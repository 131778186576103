import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ButtonRow } from '@views/components/Form'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import { sportInfoFields, SportInfoSchema } from '@views/schemas'
import { AppText, RenderRow, Form } from '@views/components'

import { COLORS } from '@assets/constants'

const SportInfo = ({ navigation }: CreateAccountStackScreenProps<'sportInfo'>) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SportInfoSchema),
  })
  const onSubmit = (data: any) => {
    navigation.navigate('beneficialOwnersReview')
    console.log(data)
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <AppText style={styles.title} bold={true}>
          Account Creation
        </AppText>
        <AppText style={styles.subTitle1}>
          Create an account to start using Players Health Services.
        </AppText>
        <AppText bold style={styles.subTitle2}>
          Organization info /{' '}
          <AppText bold style={{ color: COLORS.GRAY_500 }}>
            Sport information
          </AppText>
        </AppText>
        <Form>
          <RenderRow
            fields={[sportInfoFields.membersNum, sportInfoFields.sportType]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[sportInfoFields.seasonStartMonth]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[sportInfoFields.seasonMonthCount]}
            errors={errors}
            control={control}
          />
        </Form>
      </View>
      <ButtonRow
        buttonLabel="Next"
        onSubmit={handleSubmit(onSubmit)}
        onBack={() => navigation.navigate('beneficialOwners')}
      />
    </ScrollView>
  )
}

export default SportInfo

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: '#FFF',
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#DDDDDD',
    padding: 10,
  },
  title: {
    fontSize: 24,
    paddingTop: 32,
    paddingBottom: 6,
  },
  subTitle1: {
    fontSize: 16,
    maxWidth: 850,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
})
