import React from 'react'
import { View } from 'react-native'
import QRCode from 'react-native-qrcode-svg'

import logo from '@assets/favicon.png'

interface QRCGProps {
  qrURL: string
}

export const QRCodeGenerator = ({ qrURL }: QRCGProps) => {
  return (
    <View>
      <QRCode value={qrURL} logo={logo} logoMargin={2} logoBackgroundColor={'white'} />
    </View>
  )
}
