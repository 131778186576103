import React, { useState } from 'react'
import { View, StyleSheet, NativeScrollEvent } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import { useSystemConfig } from '@views/hooks'
import { AppText, Button } from '@views/components'

import { TERMS } from '@assets/constants'

const MARGIN_BOTTOM = 10

interface Props {
  onAccepted: () => void
}

export const Terms: React.FC<Props> = ({ onAccepted }) => {
  const { isSmallDevice } = useSystemConfig()
  const [termsRead, setTermsRead] = useState(false)

  const isBottomReached = ({
    contentOffset,
    contentSize,
    layoutMeasurement,
  }: NativeScrollEvent) => {
    const offsetY = contentOffset.y
    const layoutHeight = layoutMeasurement.height
    const contentHeight = contentSize.height

    const totalScroll = contentHeight - layoutHeight - MARGIN_BOTTOM
    return offsetY >= totalScroll
  }

  return (
    <View style={isSmallDevice ? styles.containerSmall : styles.container}>
      <AppText bold style={styles.title}>
        Players Health Terms and Conditions
      </AppText>
      <ScrollView
        onScroll={({ nativeEvent }) => isBottomReached(nativeEvent) && setTermsRead(true)}
        scrollEventThrottle={16}>
        {TERMS.map((section) => (
          <View key={section.title}>
            <AppText bold style={styles.sectionTitle}>
              {section.title}
            </AppText>
            <AppText style={styles.sectionContent}>{section.content}</AppText>
          </View>
        ))}
      </ScrollView>
      <Button
        style={styles.button}
        onPress={onAccepted}
        type="primary"
        text="Accept"
        disabled={!termsRead}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: { maxHeight: '75vh', marginBottom: MARGIN_BOTTOM },
  containerSmall: { maxHeight: '60vh', marginBottom: 10 },
  title: { fontSize: 24 },
  sectionTitle: { marginTop: 20 },
  sectionContent: { lineHeight: 24 },
  button: { marginTop: 36 },
})
