import { BusinessRepository } from '@domain/repositories/BusinessRepository'

export const businessService = (repository: BusinessRepository): BusinessRepository => ({
  getBusinesses: () => {
    return repository.getBusinesses()
  },
  getBusinessById: (id) => {
    return repository.getBusinessById(id)
  },
  createBusiness: (business) => {
    return repository.createBusiness(business)
  },
})
