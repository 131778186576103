import { AddressDTO } from '@microservices/dto/AddressDTO'
import { IBasicInfo } from '@views/schemas'

export interface BusinessDTO {
  id?: string
  entity_name: string
  type: string
  ein: string
  formation_date: Date
  formation_state: string
  email: string
  phone_number: string
  structure: string
  legal_address: AddressDTO
}

export const BusinessDTOtoBasicInfo = (businessDTO: BusinessDTO): IBasicInfo => ({
  orgId: businessDTO.id,
  orgLegalName: businessDTO.entity_name,
  orgType: businessDTO.type,
  orgEIN: businessDTO.ein,
  orgFormationDate: businessDTO.formation_date.toString(),
  orgFormationState: businessDTO.formation_state,
  orgPhone: businessDTO.phone_number,
  orgEmail: businessDTO.email,
  orgLegalStructure: businessDTO.structure,
  orgAddrStreet1: businessDTO.legal_address.address_line_1,
  orgAddrStreet2: businessDTO.legal_address.address_line_2,
  orgAddrCity: businessDTO.legal_address.city,
  orgAddrState: businessDTO.legal_address.state,
  orgAddrZip: businessDTO.legal_address.postal_code,
})

export const BasicInfoToBusinessDTO = (basicInfo: IBasicInfo): BusinessDTO => {
  const legal_address = {
    address_line_1: basicInfo.orgAddrStreet1,
    address_line_2: basicInfo.orgAddrStreet2,
    city: basicInfo.orgAddrCity,
    state: basicInfo.orgAddrState,
    postal_code: basicInfo.orgAddrZip,
  }

  return {
    entity_name: basicInfo.orgLegalName,
    type: basicInfo.orgType,
    ein: basicInfo.orgEIN,
    formation_date: new Date(basicInfo.orgFormationDate),
    formation_state: basicInfo.orgFormationState,
    phone_number: basicInfo.orgPhone,
    email: basicInfo.orgEmail,
    structure: basicInfo.orgLegalStructure,
    legal_address,
  }
}
