import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import {
  CreateAccountInitialState,
  ICreateAccountContext,
  IStateData,
} from '@views/contexts/CreateAccount/interface'
import { CreateAccountReducer } from '@views/contexts/CreateAccount/reducer'
import { IAdminInfo, IBasicInfo, IBeneficialOwnersInfo } from '@views/schemas'
import {
  ActionType,
  postAdminInfoAction,
  postBasicInfoAction,
  postOwnerInfoAction,
} from '@views/contexts/CreateAccount/actions'
import { LocalStorage } from '@microservices/instances/LocalStorage'
import { CREATE_ACCOUNT_KEY } from '@assets/constants'

interface ICreateAccountContextProps {
  children: ReactNode
}

export const CreateAccountContext = createContext<ICreateAccountContext>(
  {} as ICreateAccountContext
)
const localStorage = new LocalStorage()

export const CreateAccountProvider: FC<ICreateAccountContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(CreateAccountReducer, CreateAccountInitialState)

  const setLoading = useCallback(
    (payload) => {
      dispatch({ type: ActionType.SET_LOADING, payload })
    },
    [dispatch]
  )

  const setError = useCallback(
    (payload) => {
      dispatch({ type: ActionType.SET_ERROR, payload })
    },
    [dispatch]
  )

  const setStateData = useCallback(
    (payload) => {
      dispatch({ type: ActionType.SET_STATE_DATA, payload })
    },
    [dispatch]
  )

  const setBasicInfo = useCallback(
    (payload: IBasicInfo) => {
      dispatch({
        type: ActionType.SET_BASIC_INFO,
        payload,
      })
    },
    [dispatch]
  )

  const setAdminInfo = useCallback(
    (payload: IAdminInfo) => {
      dispatch({
        type: ActionType.SET_ADMIN_INFO,
        payload,
      })
    },
    [dispatch]
  )

  const setOwnerInfo = useCallback(
    (payload: IBeneficialOwnersInfo) => {
      dispatch({
        type: ActionType.SET_OWNER_INFO,
        payload,
      })
    },
    [dispatch]
  )

  const updateStore = useCallback(async (data: Partial<IStateData>) => {
    await localStorage.setItem(CREATE_ACCOUNT_KEY, { ...data })
  }, [])

  const postBasicInfo = useCallback(
    async (payload) => {
      try {
        setLoading(true)
        await postBasicInfoAction(payload, setBasicInfo)
        await updateStore({
          basicInfo: { ...payload },
          completedPages: ['basicInfo'],
        })
      } catch (error: any) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setBasicInfo, updateStore, setError]
  )

  const postAdminInfo = useCallback(
    async (payload) => {
      try {
        setLoading(true)
        const data = await postAdminInfoAction(payload, setAdminInfo)
        await updateStore({
          adminInfo: { ...data },
          completedPages: [...state.data.completedPages, 'adminInfo'],
        })
      } catch (error: any) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setAdminInfo, updateStore, state.data.completedPages, setError]
  )

  const postOwnerInfo = useCallback(
    async (payload) => {
      try {
        setLoading(true)
        await postOwnerInfoAction(payload, setOwnerInfo)
        await updateStore({
          beneficialOwnersInfo: { ...payload },
          completedPages: [...state.data.completedPages, 'beneficialOwners'],
        })
      } catch (error: any) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setOwnerInfo, updateStore, state.data.completedPages, setError]
  )

  useEffect(() => {
    ;(async () => {
      const storeValue = await localStorage.getItem(CREATE_ACCOUNT_KEY)
      if (storeValue === null) return
      setStateData(storeValue)
      return
    })()
    return
  }, [])

  const value = {
    state,
    postBasicInfo,
    postAdminInfo,
    postOwnerInfo,
  }

  return <CreateAccountContext.Provider value={value}>{children}</CreateAccountContext.Provider>
}
