import {
  cardItems,
  countries,
  mockRecipientNames,
  noSelectionRegex,
  phoneRegex,
  states,
} from '@assets/constants'
import { InputTypes } from '@views/components'
import { IInputProps } from '@views/components/Form'
import * as yup from 'yup'

export const issueCardFields: IIssueCardFields = {
  selectCardType: {
    placeholder: 'Select',
    label: 'Select card type',
    type: InputTypes.picker,
    name: 'selectCardType',
    items: cardItems,
  },
  recipientName: {
    placeholder: 'Select',
    label: 'Select recipient name',
    type: InputTypes.picker,
    name: 'recipientName',
    items: mockRecipientNames,
  },
  orgName: {
    placeholder:
      'Ohio Rockets Club' /* @TODO: Integrate after having the possibility to check for org names */,
    label: 'Organization Name',
    type: InputTypes.text,
    name: 'orgName',
  },
  first_name: {
    name: 'first_name',
    label: 'First Name',
    type: InputTypes.text,
    placeholder: 'Enter First Name',
  },
  last_name: {
    name: 'last_name',
    label: 'Last name',
    type: InputTypes.text,
    placeholder: 'Enter last name',
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    type: InputTypes.text,
    placeholder: 'Enter phone',
  },
  address: {
    name: 'address',
    label: 'Street address',
    type: InputTypes.text,
    placeholder: 'Enter street address',
  },
  city: {
    name: 'city',
    label: 'City',
    type: InputTypes.text,
    placeholder: 'Enter city',
  },
  state: {
    name: 'state',
    label: 'State',
    type: InputTypes.picker,
    placeholder: 'Select state',
    items: states,
  },
  country: {
    name: 'country',
    label: 'Country',
    type: InputTypes.picker,
    placeholder: 'Select country',
    items: countries,
  },
}

export interface IIssueCardFields {
  selectCardType: IInputProps
  recipientName: IInputProps
  orgName: IInputProps
  first_name: IInputProps
  last_name: IInputProps
  address: IInputProps
  city: IInputProps
  state: IInputProps
  phone: IInputProps
  country: IInputProps
}

export interface IIssueCard {
  selectCardType?: string
  recipientName?: string
  orgName?: string
  first_name?: string
  last_name?: string
  phone?: string
  address?: string
  city?: string
  state?: string
  country?: string
}

export const IssueCardSchema = [
  yup.object().shape({
    selectCardType: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  }),
  yup.object().shape({
    recipientName: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  }),
  yup.object().shape({
    first_name: yup.string().required('Required'),
    last_name: yup.string().required('Required'),
    phone: yup.string().trim().matches(phoneRegex, 'Invalid phone format').required('Required'),
    address: yup.string().required('Required'),
    city: yup.string().required('Required'),
    state: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
    country: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  }),
]
