import { FormInput, IInputProps } from '@views/components/Form'
import { StyleSheet, View } from 'react-native'
import { Control, FieldError, FieldErrors } from 'react-hook-form'
import React from 'react'
import { useSystemConfig } from '@views/hooks'

interface IRenderRow {
  fields: IInputProps[]
  errors: FieldErrors
  control: Control
}

export const RenderRow = ({ fields, errors, control }: IRenderRow) => {
  const { isMediumDevice } = useSystemConfig()
  const smallInputs = fields.length > 2

  return (
    <View style={!isMediumDevice ? styles.formRow : null}>
      {fields.map((field, idx) => {
        const inputName = field.name
        let error
        if (errors !== undefined) error = errors[inputName] as unknown as FieldError

        return (
          <View
            style={
              !isMediumDevice && (smallInputs ? styles.smallInputWrapper : styles.inputWrapper)
            }
            key={idx}>
            <FormInput
              name={field.name}
              label={field.label}
              type={field.type}
              control={control}
              error={error}
              items={field.items}
              placeholder={field.placeholder}
              rules={field.rules}
            />
          </View>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  formRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputWrapper: {
    width: '50%',
    padding: 5,
  },
  smallInputWrapper: {
    width: '33%',
    padding: 3,
  },
})
