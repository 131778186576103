import { AddressDTO } from '@microservices/dto/AddressDTO'
import { IAdminInfo } from '@views/schemas'

export interface UserDTO {
  id?: string
  first_name: string
  last_name: string
  email: string
  percent_ownership?: number
  phone: string
  dob: Date
  ssn: string
  legal_address: AddressDTO
}

export const UserDTOtoAdminInfo = (userDTO: UserDTO): IAdminInfo => ({
  id: userDTO.id,
  first_name: userDTO.first_name,
  last_name: userDTO.last_name,
  email: userDTO.email,
  phone: userDTO.phone,
  birthday: userDTO.dob.toString(),
  ssn: userDTO.ssn,
  address_line_1: userDTO.legal_address.address_line_1,
  address_line_2: userDTO.legal_address.address_line_2,
  city: userDTO.legal_address.city,
  state: userDTO.legal_address.state,
  zip_code: userDTO.legal_address.postal_code,
})

export const AdminInfoToUserDTO = (adminInfo: IAdminInfo): UserDTO => {
  const legal_address = {
    address_line_1: adminInfo.address_line_1,
    address_line_2: adminInfo.address_line_2,
    city: adminInfo.city,
    state: adminInfo.state,
    postal_code: adminInfo.zip_code,
  }

  return {
    id: adminInfo.id,
    first_name: adminInfo.first_name,
    last_name: adminInfo.last_name,
    email: adminInfo.email,
    phone: adminInfo.phone,
    dob: new Date(adminInfo.birthday),
    ssn: adminInfo.ssn,
    legal_address,
  }
}
