import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Camera as ExpoCamera, CameraType } from 'expo-camera'

import { Button } from '@views/components'

export function Camera({ setCamera, takePicture }: any) {
  return (
    <View>
      <View style={styles.cameraContainer}>
        <ExpoCamera style={styles.camera} type={CameraType.back} ref={(ref) => setCamera(ref)} />
      </View>
      <View style={styles.buttonContainer}>
        <Button type={'secondary'} onPress={takePicture} text={'Take photo'} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  camera: {
    width: '100%',
    height: '100%',
  },
  cameraContainer: {
    height: '60vw',
    width: '90vw',
  },
  buttonContainer: {
    marginTop: 20,
  },
})
