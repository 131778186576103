import React, { useEffect, useState, useContext } from 'react'
import { ScrollView, StyleSheet, View, Image } from 'react-native'
import { useFieldArray, useForm } from 'react-hook-form'
import moment from 'moment'
import Checkbox from 'expo-checkbox'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { CreateAccountContext } from '@views/contexts'
import { AppText, RenderRow, Row, Form, ButtonRow } from '@views/components'
import { beneficialOwnerFields, IBeneficialOwnersInfoFields } from '@views/schemas'

import plus from '@assets/images/plus.png'
import { COLORS } from '@assets/constants'

type TProps = {
  setShowForm: (value: boolean) => any
  onSubmit: () => any
  onBack: () => any
}

const BeneficialOwnersForm: React.FC<TProps> = ({ setShowForm, onSubmit, onBack }) => {
  const { state, postOwnerInfo } = useContext(CreateAccountContext)
  const { loading, error, data } = state
  const { beneficialOwnersInfo, basicInfo, adminInfo } = data

  const [beneficialOwnerForms, setBeneficialOwnerForms] = useState<IBeneficialOwnersInfoFields[]>(
    []
  )
  const [checked, setChecked] = useState(false)
  const [numFormsAdded, setNumFormsAdded] = useState(1)

  useEffect(() => {
    addBeneficialOwnerForm()
    // Leaving this disable line due to needing the effect to run only once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    shouldUnregister: true,
  })

  const formatFormData = (info: any, num: number) => {
    return {
      first_name: info[`firstNameBO${num}`],
      last_name: info[`lastNameBO${num}`],
      phone: info[`phoneBO${num}`],
      email: info[`emailBO${num}`],
      percentageOwnership: info[`percentageOwnershipBO${num}`],
      ssn: info[`ssnBO${num}`],
      birthday: info[`dobBO${num}`],
      address_line_1: info[`addrStreet1BO${num}`],
      address_line_2: info[`addrStreet2BO${num}`],
      city: info[`addrCityBO${num}`],
      state: info[`addrStateBO${num}`],
      zip_code: info[`addrZipBO${num}`],
    }
  }

  const submitForm = async (info: any) => {
    for (let i = 1; i <= numFormsAdded; i++) {
      if (!info[`firstNameBO${i}`] || (checked && i === 1)) continue
      const formattedUser = formatFormData(info, i)
      await postOwnerInfo({
        owner: formattedUser,
        businessId: basicInfo?.orgId,
      })
      await postOwnerInfo(formatFormData(info, i), state.data.basicInfo?.orgId)
    }
    if (error === '') onSubmit()
  }

  const { append, remove } = useFieldArray({
    control,
    name: 'BOForms',
  })

  const addBeneficialOwnerForm = () => {
    const newForm = beneficialOwnerFields(beneficialOwnerForms.length + 1)
    const newFormMap = Object.values(newForm).map((field) => ({ [field.name]: '' }))
    append(newFormMap)
    setBeneficialOwnerForms([...beneficialOwnerForms, newForm])
    if (errors.BOForms?.length) clearErrors('BOForms')
    if (numFormsAdded < 4) setNumFormsAdded(numFormsAdded + 1)
  }

  const removeBeneficialOwnerForm = (idx: number) => {
    const temp = [...beneficialOwnerForms]
    temp.splice(idx, 1)
    setBeneficialOwnerForms(temp)

    const BOFormLength = Object.keys(beneficialOwnerForms[0]).length
    const startIdx = BOFormLength * idx
    const endIdx = startIdx + BOFormLength

    for (let i = startIdx; i < endIdx; i++) remove(i)

    if (errors.BOForms?.length) clearErrors('BOForms')
  }

  const isOwnerClick = () => {
    if (!checked && adminInfo) {
      setValue('firstNameBO1', adminInfo.first_name)
      setValue('lastNameBO1', adminInfo.last_name)
      setValue('phoneBO1', adminInfo.phone)
      setValue('emailBO1', adminInfo.email)
      setValue('ssnBO1', adminInfo.ssn)
      setValue('dobBO1', moment(adminInfo.birthday).format('MM/DD/YYYY'))
      setValue('addrStreet1BO1', adminInfo.address_line_1)
      setValue('addrStreet2BO1', adminInfo.address_line_2)
      setValue('addrCityBO1', adminInfo.city)
      setValue('addrStateBO1', adminInfo.state)
      setValue('addrZipBO1', adminInfo.zip_code)
    } else {
      setValue('firstNameBO1', '')
      setValue('lastNameBO1', '')
      setValue('phoneBO1', '')
      setValue('emailBO1', '')
      setValue('ssnBO1', '')
      setValue('dobBO1', '')
      setValue('addrStreet1BO1', '')
      setValue('addrStreet2BO1', '')
      setValue('addrCityBO1', '')
      setValue('addrStateBO1', '')
      setValue('addrZipBO1', '')
    }
    setChecked(!checked)
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <AppText style={styles.title} bold={true}>
          Account Creation
        </AppText>
        <AppText style={styles.subTitle1}>
          Create an account to start using Players Health Services.
        </AppText>
        <AppText bold style={styles.subTitle2}>
          Organization info /{' '}
          <AppText bold style={{ color: COLORS.GRAY_500 }}>
            Beneficial owners info
          </AppText>
        </AppText>
        <AppText style={styles.subTitle2}>
          We need to collect information about each beneficial owner or administrative authority
          (for non-profits). Don't know your beneficial owners' or administrative authorities'
          information? We can{' '}
          <TouchableOpacity onPress={() => setShowForm(false)}>
            <AppText style={styles.link}>send them an email</AppText>
          </TouchableOpacity>{' '}
          so they can add their own information.
        </AppText>
        <Row style={styles.checkBoxWrapper}>
          <Checkbox
            value={checked}
            onValueChange={isOwnerClick}
            style={styles.checkBox}
            disabled={!adminInfo}
            color={!checked ? COLORS.BACKGROUND : COLORS.LIGHT_GREEN}
          />
          <AppText>I am a beneficial owner</AppText>
        </Row>
        <Form showDisclaimer>
          {beneficialOwnerForms.map((BOForm, idx) => {
            return (
              <View style={styles.formWrapper} key={`owner-${idx + 1}-form`}>
                <Row>
                  <AppText bold style={styles.subHeader}>
                    Beneficial Owner {idx + 1}
                  </AppText>
                  {idx > 0 && (
                    <TouchableOpacity onPress={() => removeBeneficialOwnerForm(idx)}>
                      <AppText style={styles.removeOwnerText}>Remove</AppText>
                    </TouchableOpacity>
                  )}
                </Row>
                <View>
                  <RenderRow
                    fields={[BOForm.firstName, BOForm.lastName]}
                    errors={errors}
                    control={control}
                  />
                  <RenderRow
                    fields={[BOForm.phone, BOForm.email, BOForm.percentageOwnership]}
                    errors={errors}
                    control={control}
                  />
                  <RenderRow fields={[BOForm.ssn, BOForm.dob]} errors={errors} control={control} />
                  <RenderRow
                    fields={[BOForm.addrStreet1, BOForm.addrStreet2]}
                    errors={errors}
                    control={control}
                  />
                  <RenderRow
                    fields={[BOForm.addrCity, BOForm.addrState, BOForm.addrZip]}
                    errors={errors}
                    control={control}
                  />
                </View>
              </View>
            )
          })}
          {beneficialOwnerForms.length <= 3 && (
            <View style={styles.addOwnerBtn}>
              <TouchableOpacity onPress={addBeneficialOwnerForm}>
                <Row>
                  <Image source={plus} style={styles.plusIcon} />
                  <AppText style={styles.addOwnerText}>Add Another Beneficial Owner</AppText>
                </Row>
              </TouchableOpacity>
            </View>
          )}
        </Form>
      </View>
      <ButtonRow buttonLabel="Next" onSubmit={handleSubmit(submitForm)} onBack={onBack} />
    </ScrollView>
  )
}

export default BeneficialOwnersForm

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    paddingTop: 32,
    paddingBottom: 6,
  },
  subTitle1: {
    fontSize: 16,
    maxWidth: 850,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
  subHeader: {
    fontSize: 16,
    marginRight: 25,
  },
  minusIcon: {
    width: 10,
    marginRight: 5,
  },
  plusIcon: {
    height: 24,
    width: 24,
  },
  removeOwnerBtn: {
    marginLeft: 30,
  },
  addOwnerText: {
    marginTop: 4,
  },
  link: {
    textDecorationLine: 'underline',
  },
  formWrapper: {
    borderBottomColor: COLORS.BACKGROUND,
    borderBottomWidth: 1,
    marginBottom: 25,
  },
  formRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputWrapper: {
    width: '50%',
    padding: 5,
  },
  smallInputWrapper: {
    width: '33%',
    padding: 3,
  },
  addOwnerBtn: {
    marginBottom: 50,
    width: 250,
  },
  removeOwnerText: {
    color: COLORS.PRIMARY,
    fontSize: 14,
    marginLeft: 20,
    marginTop: 2,
    textDecorationLine: 'underline',
  },
  checkBoxWrapper: {
    marginTop: 35,
  },
  checkBox: {
    marginRight: 10,
  },
})
