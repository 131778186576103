import React, { FC } from 'react'
import { View, StyleSheet, Image } from 'react-native'

import { AppText } from '@views/components'

type Props = {
  color: string
  title: string
  imgUrl: any
  style?: {}
  children?: any
}

export const Card: FC<Props> = ({ color, title, imgUrl, style, children }) => {
  return (
    <View style={[styles.card, style, { backgroundColor: color }]}>
      <Image source={imgUrl} style={styles.img} />
      <AppText style={styles.title} bold>
        {title}
      </AppText>
      <View style={styles.body}>{children}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 20px 20px',
    borderRadius: 8,
  },
  img: {
    width: 210,
    height: 160,
  },
  title: {
    fontSize: 16,
  },
  body: {
    marginBottom: 20,
  },
})
