import {
  IAdminInfo,
  IBasicInfo,
  IBeneficialOwnerEmails,
  IBeneficialOwnersInfo,
  ISportInfo,
} from '@views/schemas'

export interface ICreateAccountState {
  loading: boolean
  error: string
  data: IStateData
}

export interface IStateData {
  completedPages: string[]
  basicInfo?: IBasicInfo
  adminInfo?: IAdminInfo
  beneficialOwnersInfo?: IBeneficialOwnersInfo[]
  beneficialOwnersEmails?: IBeneficialOwnerEmails
  sportInfo?: ISportInfo
}

export const CreateAccountInitialState: ICreateAccountState = {
  loading: false,
  error: '',
  data: {
    completedPages: [],
    basicInfo: undefined,
    adminInfo: undefined,
    beneficialOwnersInfo: undefined,
    beneficialOwnersEmails: undefined,
    sportInfo: undefined,
  },
}

export interface ICreateAccountContext {
  state: ICreateAccountState
  postBasicInfo: (postData: IBasicInfo) => Promise<void>
  postAdminInfo: (postData: IAdminInfo) => Promise<void>
  postOwnerInfo: (postData: any, businessId?: string, admin?: IAdminInfo) => Promise<void>
}
