import { AppText } from '@views/components'
import React from 'react'
import { View, StyleSheet } from 'react-native'

const TransactionsPage: React.FC = () => {
  return (
    <View style={styles.container}>
      <AppText semiBold style={{ fontSize: 30 }}>
        TransactionsPage
      </AppText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default TransactionsPage
