import * as yup from 'yup'
import { InputTypes, IInputProps } from '@views/components'
import { currencyIntRegex } from '@assets/constants'

export const fundAccountFields: IFundAccountFields = {
  sportsOrgName: {
    name: 'sportsOrgName',
    label: 'Sports organization name',
    type: InputTypes.text,
    placeholder: 'Enter sports organization name',
  },
  depositType: {
    name: 'depositType',
    label: 'Type of deposit',
    type: InputTypes.radioButton,
    items: [
      {
        label: 'Remote by check',
        value: 'check',
      },
      {
        label: 'Deposit by ACH',
        value: 'ach',
      },
    ],
  },
}

export const achAmountField = {
  name: 'amount',
  label: 'Amount',
  type: InputTypes.text,
  placeholder: 'Enter amount',
}

interface IFundAccountFields {
  sportsOrgName: IInputProps
  depositType: IInputProps
}

interface IFundAccount {
  sportsOrgName: string
  depositType: string
}

export const FundAccountSchema = yup.object().shape({
  sportsOrgName: yup.string().required('Required'),
  depositType: yup.string().required('Required'),
})

export const AchSchema = yup.object().shape({
  amount: yup
    .string()
    .required('Required')
    .matches(currencyIntRegex, 'Invalid amount')
    .test('minAmount', 'Minimum $50', (value) => {
      if (!value) return false
      const amount = value.replace(/\D/g, '')
      return parseInt(amount, 10) >= 50
    }),
})
