import React from 'react'
import { StyleSheet } from 'react-native'
import { FieldError } from 'react-hook-form'

import { AppText } from '@views/components'

interface IErrorMessage {
  error: FieldError | undefined
}

export const ErrorMessage = ({ error }: IErrorMessage) => {
  const errorMessages = (type: string) => {
    const errorTypes: Record<string, any> = {
      required: 'Required',
      pattern: 'Invalid format',
      default: 'Something went wrong',
    }

    return type ? errorTypes[type] : errorTypes.default
  }
  let message
  if (error && error.message !== '') message = error.message
  else if (error && error.type) message = errorMessages(error.type)
  else message = errorMessages('default')
  return <AppText style={styles.errorText}>{message}</AppText>
}

const styles = StyleSheet.create({
  errorText: {
    color: 'red',
  },
})
