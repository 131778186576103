import React from 'react'
import { View, StyleSheet } from 'react-native'
import { AppText } from '@views/components'

export default function LoadingPage() {
  return (
    <View style={styles.container}>
      <AppText bold style={styles.text}>
        Loading...
      </AppText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 30,
  },
})
