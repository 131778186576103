import React, { FC, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import Slider from '@react-native-community/slider'
import { AppText } from '@views/components'

interface ISliderProps {
  onChange: any
  onBlur: any
  value: any
}

export const RNSlider: FC<ISliderProps> = ({}) => {
  const [sliderValue, setSliderValue] = useState(1)
  const [sliderValuePosition, setSliderValuePosition] = useState(6.5)
  return (
    <View>
      <Slider
        step={1}
        minimumTrackTintColor={'#3b2df5'}
        maximumTrackTintColor={'#f6f6f8'}
        minimumValue={1}
        maximumValue={12}
        onValueChange={(OnChangeSliderValue) => (
          setSliderValue(OnChangeSliderValue), setSliderValuePosition(6.7 * OnChangeSliderValue)
        )}
        style={styles.slider}
        thumbTintColor={'#1fa0f0'}
        tapToSeek={false}
      />
      <View style={styles.sliderOptionsContainer}>
        <AppText>1</AppText>
        <AppText>6</AppText>
        <AppText>12</AppText>
      </View>
      <View style={styles.sliderValueContainer}>
        <View
          style={{
            position: 'absolute',
            left: `${sliderValuePosition}%`,
            top: -30,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 4,
            },
            shadowOpacity: 0.3,
            shadowRadius: 4.65,
            elevation: 8,
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: 'transparent',
            borderWidth: 5,
            backgroundColor: '#fff',
          }}>
          <AppText style={styles.textValue}>
            {sliderValue} {sliderValue > 1 ? 'months' : 'month'}
          </AppText>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  sliderOptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
  },
  textValue: {
    margin: 6,
  },
  sliderValueContainer: {
    display: 'flex',
    width: '100%',
  },
  slider: {
    width: '100%',
    opacity: 10,
    height: 50,
  },
})
