export enum COLORS {
  DARK = '#141414',
  WHITE = '#FFF',
  PRIMARY = '#5B4FF9',
  BACKGROUND = '#EAEAEE',
  GRAY_200 = '#F6F6F8',
  GRAY_500 = '#AEB4BB',
  GRAY_700 = '#91979F',
  LIGHT = '##E5E5E5',
  LIGHT_GREEN = '#4CD6BE',
  LIGHT_RED = '#EB8888',
  CYAN = '#50E3CA',
  CYAN_200 = '#D9F9F1',
  PURPLE_LIGHT = '#DACAF5',
  BLUE_300 = '#DAEDFB',
  AZURE_LIGHT = '#F0F6FB',
  ORANGE_LIGHT = '#FCEFD4',
}
