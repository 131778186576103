import React from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'

import AppLoading from 'expo-app-loading'

import Navigation from '@views/navigation'
import { useCustomFonts } from '@views/hooks'
import { Modal, ModalRef } from '@views/components'

import { Auth0Provider } from '@auth0/auth0-react'
import { AuthContextProvider, DisclaimerContextProvider } from '@views/contexts'
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@assets/constants'

export default function App() {
  let [fontsLoaded] = useCustomFonts()

  if (!fontsLoaded) return <AppLoading />

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}>
      <AuthContextProvider>
        <DisclaimerContextProvider>
          <SafeAreaView style={styles.wrapper}>
            <Navigation />
            <Modal
              ref={(ref) => {
                ModalRef.ref = ref
              }}
            />
          </SafeAreaView>
        </DisclaimerContextProvider>
      </AuthContextProvider>
    </Auth0Provider>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    height: '100%',
  },
})
