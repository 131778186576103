import { COLORS } from '@assets/constants'
import step1of2Img from '@assets/images/step-1-of-2.png'
import step2of2Img from '@assets/images/step-2-of-2.png'
import successImg from '@assets/images/sucess-check.png'
import warningImg from '@assets/images/warning.png'
import { AntDesign } from '@expo/vector-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppText, Button, closeModal, Form, FormInput, RenderRow } from '@views/components'
import { issueCardFields, IssueCardSchema } from '@views/schemas'
import React, { useState } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { Image, StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

enum FORM_STEPS {
  CARD_TYPE /* equal to 0 */,
  CARD_DETAILS /* equal to 1 */,
  RECIPIENT_DETAILS /* etc */,
  SUCCESS,
  DATA_NOT_SAVED,
}

const IssueCard: React.FC = () => {
  const [displayStatus, setDisplayStatus] = useState(FORM_STEPS.CARD_TYPE)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(IssueCardSchema[displayStatus]) })
  //the IssueCardSchema is also an array to validate only when it's in the right step

  const handleXClick = () => {
    if (displayStatus === FORM_STEPS.SUCCESS) return closeModal()

    return setDisplayStatus(FORM_STEPS.DATA_NOT_SAVED)
  }

  const onSubmit = async (info: any) => {
    console.log(info) /* @TODO: remove it after the integration
     Left this one for testing */
    switch (displayStatus) {
      case FORM_STEPS.CARD_TYPE:
        return setDisplayStatus(FORM_STEPS.CARD_DETAILS)

      case FORM_STEPS.CARD_DETAILS:
        if (info.selectCardType === 'virtual') return setDisplayStatus(FORM_STEPS.SUCCESS)
        else if (info.selectCardType === 'physical')
          return setDisplayStatus(FORM_STEPS.RECIPIENT_DETAILS)
        break

      case FORM_STEPS.RECIPIENT_DETAILS:
        return setDisplayStatus(FORM_STEPS.SUCCESS)

      default:
        return null
    }
  }

  const selectCardTypeError = errors.selectCardType as unknown as FieldError
  const recipientNameError = errors.recipientName as unknown as FieldError
  const phoneError = errors.phone as unknown as FieldError

  const renderStep = () => {
    switch (displayStatus) {
      case FORM_STEPS.CARD_TYPE:
        return (
          <>
            <FormInput
              {...issueCardFields.selectCardType}
              control={control}
              error={selectCardTypeError}
            />
            <Button onPress={handleSubmit(onSubmit)} text="Continue" />
          </>
        )
      case FORM_STEPS.CARD_DETAILS:
        return (
          <>
            <View style={styles.subtitleWrapper}>
              <AppText style={styles.subtitle}>Card Details</AppText>
              <View style={styles.stepWrapper}>
                <Image source={step1of2Img} style={styles.stepImage} />
                <AppText style={styles.subtitle}>Step 1/2</AppText>
              </View>
            </View>
            <FormInput
              disabled
              {...issueCardFields.orgName}
              control={control}
              error={selectCardTypeError}
            />
            <FormInput
              {...issueCardFields.recipientName}
              control={control}
              error={recipientNameError}
            />
            <Button onPress={handleSubmit(onSubmit)} text="Continue to Recipient Details" />
          </>
        )
      case FORM_STEPS.RECIPIENT_DETAILS:
        return (
          <>
            <View style={styles.subtitleWrapper}>
              <AppText style={styles.subtitle}>Recipient Details</AppText>
              <View style={styles.stepWrapper}>
                <Image source={step2of2Img} style={styles.stepImage} />
                <AppText style={styles.subtitle}>Step 2/2</AppText>
              </View>
            </View>
            <RenderRow
              fields={[issueCardFields.first_name, issueCardFields.last_name]}
              errors={errors}
              control={control}
            />
            <RenderRow
              fields={[issueCardFields.country, issueCardFields.city]}
              errors={errors}
              control={control}
            />
            <RenderRow
              fields={[issueCardFields.address, issueCardFields.state]}
              errors={errors}
              control={control}
            />
            <FormInput {...issueCardFields.phone} control={control} error={phoneError} />
            <Button onPress={handleSubmit(onSubmit)} text="Issue Card" />
            <TouchableOpacity
              style={styles.backButton}
              onPress={() => setDisplayStatus(FORM_STEPS.CARD_DETAILS)}>
              <AppText>Back to Card Details</AppText>
            </TouchableOpacity>
          </>
        )
      default:
        return null /* For the success and error cases, in which the header are different */
    }
  }
  return (
    <View style={styles.container}>
      {displayStatus === FORM_STEPS.SUCCESS ? (
        <View style={styles.messageWrapper}>
          <Image source={successImg} style={styles.image} />
          <AppText style={styles.successTitle}>Success</AppText>
          <AppText style={styles.successSubtitle}>
            Card has been successfully issued. The shipping should take up to 7 business days
          </AppText>
          <Button onPress={() => closeModal()} text="Back to business account" />
        </View>
      ) : displayStatus === FORM_STEPS.DATA_NOT_SAVED ? (
        <View style={styles.messageWrapper}>
          <Image source={warningImg} style={styles.image} />
          <AppText style={styles.successTitle}>The entered data was not saved</AppText>
          <AppText style={styles.successSubtitle}>
            Are you sure you want to close and go back to your Business Account? You can lose your
            data
          </AppText>
          <Button
            onPress={() => setDisplayStatus(FORM_STEPS.CARD_DETAILS)}
            text="Continue Issuing"
          />
          <TouchableOpacity style={styles.backButton} onPress={() => closeModal()}>
            <AppText>Back to business account</AppText>
          </TouchableOpacity>
        </View>
      ) : (
        <>
          <View style={styles.header}>
            <AppText style={styles.title}>Issue Card</AppText>
            <AntDesign
              name="closesquare"
              size={24}
              color={COLORS.GRAY_700}
              onPress={() => handleXClick()}
            />
          </View>
          <Form>{renderStep()}</Form>
        </>
      )}
    </View>
  )
}

export default IssueCard

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 400,
  },
  title: { fontStyle: 'normal', fontWeight: '700', fontSize: 24 },
  subtitle: { fontStyle: 'normal', fontWeight: '600', fontSize: 16 },
  subtitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  successTitle: { fontStyle: 'normal', fontWeight: '700', fontSize: 24, marginBottom: 10 },
  successSubtitle: { marginBottom: 32, paddingHorizontal: 5 },
  image: {
    width: 100,
    height: 60,
    resizeMode: 'contain',
    marginBottom: 24,
    alignSelf: 'center',
  },
  stepWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  stepImage: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginRight: 8,
  },
  backButton: {
    marginTop: 28,
    textAlign: 'center',
  },
})
