import modalRef from './ModalRef'
import { ModalOptions } from './types'

export const openModal = (options: ModalOptions) => {
  return modalRef.ref?.openModal(options)
}

export const closeModal = () => {
  return modalRef.ref?.closeModal()
}
