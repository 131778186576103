import React, { useEffect } from 'react'
import { useAuth } from '@views/hooks'
import { AppText } from '@views/components'
import { StyleSheet } from 'react-native'

const LoginWithRedirect = ({ route }: any) => {
  const { login } = useAuth()

  useEffect(() => {
    ;(async () => {
      await login(route.params.redirect_uri)
      return
    })()
    return
  })
  return <AppText style={styles.paddedText}>Redirecting...</AppText>
}

export default LoginWithRedirect

const styles = StyleSheet.create({
  paddedText: {
    padding: 20,
  },
})
