import React from 'react'
import { ImageBackground, View, StyleSheet } from 'react-native'
import { AppText, Button } from '@views/components'
import cardBg from '@assets/images/card-bg.png'
import { COLORS } from '@assets/constants'
import { useSystemConfig } from '@views/hooks'

export const SupportCard: React.FC = () => {
  const { isSmallDevice } = useSystemConfig()
  return (
    <ImageBackground
      source={cardBg}
      style={[styles.container, isSmallDevice ? styles.supportCardMobile : styles.supportCard]}>
      <View>
        <AppText bold style={styles.cardTitle}>
          NEED HELP?
        </AppText>
        <AppText light>Our team is ready to assist.</AppText>
      </View>
      {/* @TODO: ADD SUPORT ACTION  */}
      <Button type="light" text="Go to Support" onPress={() => {}} style={{ height: undefined }} />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.PURPLE_LIGHT,
    alignSelf: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    padding: 15,
  },
  supportCard: {
    height: 200,
    width: 200,
  },
  supportCardMobile: {
    width: '100%',
    height: 175,
  },
  cardTitle: { fontSize: 20 },
})
