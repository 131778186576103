import React from 'react'
import { View, StyleSheet } from 'react-native'

type TProps = {
  style?: {}
  children?: any
}

export const Row: React.FC<TProps> = ({ style, children }) => {
  return <View style={[styles.row, style]}>{children}</View>
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
})
