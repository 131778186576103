import React from 'react'
import { Entypo, Ionicons, MaterialIcons, SimpleLineIcons } from '@expo/vector-icons'

export const OTHER_ROUTE = 'other'
export const SETTINGS_ROUTE = 'settings'
export const TRANSACTIONS_ROUTE = 'transactions'
export const BUSINESS_ACCOUNT_ROUTE = 'businessAccount'
export const PERSONAL_DOCUMENTS_ROUTE = 'personalDocuments'

export const mainNavPages = [
  {
    title: 'Business Account',
    page: BUSINESS_ACCOUNT_ROUTE,
    getIcon: (color: string) => <Entypo name="wallet" size={20} color={color} />,
  },
  {
    title: 'Transactions',
    page: TRANSACTIONS_ROUTE,
    getIcon: (color: string) => <Ionicons name="md-newspaper-sharp" size={20} color={color} />,
  },
  {
    title: 'Settings',
    page: SETTINGS_ROUTE,
    getIcon: (color: string) => <MaterialIcons name="settings" size={20} color={color} />,
  },
]

export const mainNavPagesMobile = [
  ...mainNavPages,
  {
    title: 'Other',
    page: OTHER_ROUTE,
    getIcon: (color: string) => <SimpleLineIcons name="options-vertical" size={20} color={color} />,
  },
]
