import React from 'react'
import { StyleSheet, View } from 'react-native'
import { DetailsItem } from './DetailsItem'
import { useSystemConfig } from '@views/hooks'

interface Item {
  label: string
  value: any
  isMasked?: boolean
}

interface Props {
  content: Item[]
}

export const DetailsModal: React.FC<Props> = ({ content }) => {
  const { isSmallDevice } = useSystemConfig()

  return (
    <View style={!isSmallDevice ? styles.container : styles.containerSmall}>
      {content.map((item) => {
        return <DetailsItem label={item.label} value={item.value} isMasked={item.isMasked} />
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 400,
    marginTop: 24,
  },
  containerSmall: {
    width: 300,
  },
})
