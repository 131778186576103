import React from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { AppText, DetailsModal, ListPopover, openModal } from '@views/components'
import { COLORS } from '@assets/constants'
import actionIcon from '@assets/images/action-icon.png'
import { currencyFormatter } from '@views/utils'
import { AntDesign, FontAwesome5, SimpleLineIcons } from '@expo/vector-icons'

type Props = {
  showDepositsTab: boolean
  issuedMemberCards: boolean
  depositsMade: boolean
}

const DepositsTab = ({ depositsMade }: any) => {
  if (!depositsMade)
    return (
      <View style={styles.clubTeamInfocontainer}>
        <View>
          <AppText style={styles.noElements}>No deposits made yet.</AppText>
        </View>
      </View>
    )
  return (
    <View style={styles.clubTeamInfocontainer}>
      <View>
        <View style={styles.clubInfoHeader}>
          <AppText style={[styles.tabHeader, { width: '25%' }]}>Sender</AppText>
          <AppText style={[styles.tabHeader, { width: '25%' }]}>Date</AppText>
          <AppText style={[styles.tabHeader, { width: '20%' }]}>Status</AppText>
          <AppText style={[styles.tabHeader, { width: '25%' }]}>Amount</AppText>
        </View>

        <View style={styles.clubInfoRow}>
          <AppText style={styles.sender}>Barbara Fuller</AppText>
          <AppText style={styles.date}>02/28/22</AppText>
          <AppText style={styles.depositStatus}>Active</AppText>
          <AppText style={styles.depositAmount}>+ {currencyFormatter(20000)}</AppText>
          <Image style={styles.dots} source={actionIcon} />
        </View>
      </View>
    </View>
  )
}

const MemberCards = ({ issuedMemberCards }: any) => {
  const onOverviewClick = () => {
    openModal({
      dismiss: true,
      title: 'Account Details',
      content: (
        <DetailsModal
          content={[
            { label: 'Cardholder name', value: 'Barbara Fuller' },
            { label: 'Card number', value: '4539071912363424' },
            { label: 'Cardholder role', value: 'Board member' },
            { label: 'Department', value: 'Players Health' },
            { label: 'Date created', value: '07/03/22' },
            { label: 'Status', value: 'Active' },
          ]}
        />
      ),
    })
  }

  if (!issuedMemberCards)
    return (
      <View style={styles.clubTeamInfocontainer}>
        <View>
          <AppText style={styles.noElements}>You have not issued any member cards.</AppText>
        </View>
      </View>
    )
  return (
    <View style={styles.clubTeamInfocontainer}>
      <View>
        <View style={styles.clubInfoHeader}>
          <AppText style={[styles.tabHeader]}>Card</AppText>
          <AppText style={[styles.tabHeader]}>Status</AppText>
          <AppText style={[styles.tabHeader]}>Amount</AppText>
          <View />
        </View>

        <View style={styles.clubInfoRow}>
          <AppText style={styles.cardMember}>Barbara Fuller</AppText>
          <AppText style={styles.memberStatus}>Active</AppText>
          <AppText style={styles.cardAmount}>{currencyFormatter(20000)}</AppText>

          <ListPopover>
            <View style={[styles.popoverContainer, styles.shadowProp]}>
              <TouchableOpacity style={styles.popoverOptions} onPress={onOverviewClick}>
                <FontAwesome5 name="list-alt" size={16} color={COLORS.GRAY_700} />
                <AppText style={styles.popoverOptionsText}>View details</AppText>
              </TouchableOpacity>

              <TouchableOpacity style={styles.popoverOptions} onPress={onOverviewClick}>
                <SimpleLineIcons name="lock" size={16} color={COLORS.GRAY_700} />
                <AppText style={styles.popoverOptionsText}>Lock card</AppText>
              </TouchableOpacity>

              <TouchableOpacity style={styles.popoverOptions} onPress={onOverviewClick}>
                <AntDesign name="closesquare" size={16} color={COLORS.GRAY_700} />
                <AppText style={styles.popoverOptionsText}>Suspend member</AppText>
              </TouchableOpacity>
            </View>
          </ListPopover>
        </View>
      </View>
    </View>
  )
}

const AccountInformation: React.FC<Props> = ({
  showDepositsTab,
  issuedMemberCards,
  depositsMade,
}) => {
  return showDepositsTab ? (
    <DepositsTab depositsMade={depositsMade} />
  ) : (
    <MemberCards issuedMemberCards={issuedMemberCards} />
  )
}

const styles = StyleSheet.create({
  clubTeamInfocontainer: {
    width: '100%',
    backgroundColor: COLORS.WHITE,
  },
  noElements: {
    fontSize: 16,
    fontWeight: '400',
  },
  clubInfoHeader: {
    paddingTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabHeader: {
    fontSize: 12,
    fontWeight: '500',
    width: '100%',
  },
  cardMember: {
    fontSize: 14,
    fontWeight: '600',
    width: '100%',
  },
  memberStatus: {
    fontWeight: '300',
    fontSize: 14,
    width: '100%',
  },
  cardAmount: {
    fontSize: 14,
    fontWeight: '700',
    width: '100%',
  },
  sender: {
    width: '25%',
    fontSize: 14,
    fontWeight: '600',
  },
  date: {
    width: '25%',
    fontSize: 14,
    fontWeight: '300',
  },
  depositStatus: {
    fontWeight: '300',
    fontSize: 14,
    width: '20%',
  },
  depositAmount: {
    fontSize: 14,
    fontWeight: '700',
    width: '25%',
  },
  dots: {
    resizeMode: 'contain',
    height: 20,
    width: '5%',
  },
  clubInfoRow: {
    paddingVertical: 24,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    borderBottomWidth: 3,
    borderBottomColor: COLORS.GRAY_200,
    justifyContent: 'space-between',
  },
  shadowProp: {
    shadowColor: '#171717',
    shadowOffset: { width: 2, height: 6 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  popoverContainer: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    width: 180,
    height: 100,
    paddingHorizontal: 12,
    alignItems: 'flex-start',
    paddingVertical: 12,
  },
  popoverOptions: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
  },
  popoverOptionsText: {
    color: COLORS.GRAY_700,
    marginLeft: 8,
  },
})

export default AccountInformation
