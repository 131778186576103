import React from 'react'
import { View, ScrollView, StyleSheet, Image, TouchableOpacity } from 'react-native'

import { AppText, Row, ButtonRow, openModal, KYCModal } from '@views/components'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import { useSystemConfig } from '@views/hooks'

import pending from '@assets/images/pending.png'
import blackCheck from '@assets/images/black_check.png'
import { COLORS } from '@assets/constants'

const BeneficialOwnersReview = ({
  navigation,
}: CreateAccountStackScreenProps<'beneficialOwnersReview'>) => {
  const { isLargeDevice, isExtraLargeDevice } = useSystemConfig()

  const onSubmit = () => {
    navigation.navigate('fundAccount')
    // TODO submit account creation data
    // eslint-disable-next-line no-console
    console.log('Form submit')
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <AppText style={styles.title} bold>
          Account Creation
        </AppText>
        <AppText style={styles.subTitle1}>
          Keep track of which beneficial owners have entered their personal information. Once all
          beneficial owners have entered their information, you may submit your application.
        </AppText>
        <AppText bold style={styles.subTitle2}>
          Organization info /{' '}
          <AppText bold style={{ color: COLORS.GRAY_500 }}>
            Beneficial owners review
          </AppText>
        </AppText>
        {/* @TODO update to only show status for emailed BOs & adjust responsiveness */}
        <Row
          style={[
            styles.reviewRow,
            !isExtraLargeDevice ? styles.reviewRowLarge : isLargeDevice && styles.reviewCol,
          ]}>
          <View>
            <AppText style={styles.label} semiBold>
              Beneficial owner/administrative authority 1
            </AppText>
            <Row>
              <Image source={pending} style={[styles.statusIcon, styles.pendingIcon]} />
              <AppText style={[styles.status, isLargeDevice && styles.statusMobile]} semiBold>
                Information Pending
              </AppText>
            </Row>
          </View>
          <View>
            <AppText style={styles.label} semiBold>
              Beneficial owner/administrative authority 2
            </AppText>
            <Row>
              <Image source={blackCheck} style={[styles.statusIcon, styles.checkIcon]} />
              <AppText style={styles.status} semiBold>
                Information Received
              </AppText>
            </Row>
          </View>
        </Row>
        <Row
          style={[
            styles.reviewRow,
            !isExtraLargeDevice ? styles.reviewRowLarge : isLargeDevice && styles.reviewCol,
          ]}>
          <View>
            <AppText style={styles.label} semiBold>
              Beneficial owner/administrative authority 3
            </AppText>
            <Row>
              <Image source={blackCheck} style={[styles.statusIcon, styles.checkIcon]} />
              <AppText style={[styles.status, isLargeDevice && styles.statusMobile]} semiBold>
                Information Received
              </AppText>
            </Row>
          </View>
          <View>
            <AppText style={styles.label} semiBold>
              Beneficial owner/administrative authority 4
            </AppText>
            <Row>
              <Image source={pending} style={[styles.statusIcon, styles.pendingIcon]} />
              <AppText style={[styles.status, isLargeDevice && styles.statusMobile]} semiBold>
                Information Pending
              </AppText>
            </Row>
          </View>
        </Row>
      </View>
      {/*@TODO Remove this button that triggers the KYC modal */}
      <TouchableOpacity
        onPress={() =>
          openModal({
            dismiss: false,
            content: <KYCModal navigation={navigation} />,
          })
        }>
        <AppText>Trigger KYC Modal</AppText>
      </TouchableOpacity>
      <ButtonRow onSubmit={onSubmit} onBack={() => navigation.navigate('sportInfo')} />
    </ScrollView>
  )
}

export default BeneficialOwnersReview

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    paddingTop: 32,
    paddingBottom: 6,
  },
  subTitle1: {
    fontSize: 16,
    maxWidth: 850,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
  link: {
    textDecorationLine: 'underline',
  },
  reviewRow: {
    justifyContent: 'space-between',
    marginBottom: 85,
  },
  reviewRowLarge: {
    paddingRight: 300,
  },
  reviewCol: {
    flexDirection: 'column',
    marginBottom: 85,
  },
  label: {
    fontSize: 16,
    marginBottom: 22.5,
  },
  status: {
    fontSize: 18,
  },
  statusMobile: {
    marginBottom: 85,
  },
  statusIcon: {
    marginRight: 16.5,
  },
  checkIcon: {
    height: 11.5,
    width: 16.5,
    marginTop: 5,
  },
  pendingIcon: {
    height: 24,
    width: 24,
  },
})
