import React, { ReactNode } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { AppText } from '../AppText'

interface Props {
  image: any
  color: string
  children: ReactNode
  onPress: () => void
}
export const IconOption: React.FC<Props> = ({ children, color, image, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.optionContainer}>
      <View style={[{ backgroundColor: color }, styles.optionsIconContainer]}>
        <Image style={styles.optionsIcon} source={image} />
      </View>
      <AppText style={styles.optionsTitles}>{children}</AppText>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  optionContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  optionsIconContainer: {
    borderRadius: 10,
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionsIcon: {
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  optionsTitles: {
    fontWeight: '500',
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 10,
  },
})
