import React, { useContext, useEffect } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment/moment'

import { ButtonRow } from '@views/components/Form'
import { AppText, RenderRow, Form } from '@views/components'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import { adminInfoFields, AdminInfoSchema } from '@views/schemas'
import { CreateAccountContext } from '@views/contexts'
import { useDisclaimer } from '@views/hooks'

import { COLORS } from '@assets/constants'

const AdminInfo = ({ navigation }: CreateAccountStackScreenProps<'adminInfo'>) => {
  const { state, postAdminInfo } = useContext(CreateAccountContext)
  const { isDisclaimerAccepted, openDisclaimers } = useDisclaimer()

  const { loading, error, data } = state

  const { adminInfo } = data

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AdminInfoSchema),
  })
  useEffect(() => {
    reset({
      ...adminInfo,
      birthday: adminInfo?.birthday !== '' ? moment(adminInfo?.birthday).format('MM/DD/YYYY') : '',
    })
  }, [adminInfo, reset])

  const onSubmit = async (info: any) => {
    if (!isDisclaimerAccepted)
      openDisclaimers({
        onAccepted: async () => {
          await sendAdminInfo(info)
        },
      })
    else await sendAdminInfo(info)
    if (error === '') navigation.navigate('beneficialOwners', info)
  }

  const sendAdminInfo = async (info: any) => {
    // @TODO add update route
    // if (adminInfo?.id) await updateAdminInfo(info)

    await postAdminInfo(info)
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <AppText style={styles.title} bold>
          Account Creation
        </AppText>
        <AppText style={styles.subTitle1}>
          Create an account to start using Players Health Services.
        </AppText>
        <AppText bold style={styles.subTitle2}>
          Organization info /{' '}
          <AppText bold style={{ color: COLORS.GRAY_500 }}>
            Admin info
          </AppText>
        </AppText>
        <Form showDisclaimer>
          <RenderRow
            fields={[adminInfoFields.first_name, adminInfoFields.last_name]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[adminInfoFields.phone, adminInfoFields.email]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[adminInfoFields.ssn, adminInfoFields.birthday]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[adminInfoFields.address_line_1, adminInfoFields.address_line_2]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[adminInfoFields.city, adminInfoFields.state, adminInfoFields.zip_code]}
            errors={errors}
            control={control}
          />
        </Form>
      </View>
      <ButtonRow
        buttonLabel="Next"
        onSubmit={handleSubmit(onSubmit)}
        onBack={() => navigation.navigate('basicInfo')}
      />
    </ScrollView>
  )
}

export default AdminInfo

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    paddingTop: 32,
    paddingBottom: 6,
  },
  subTitle1: {
    fontSize: 16,
    maxWidth: 850,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
  link: {
    textDecorationLine: 'underline',
  },
  formWrapper: {
    borderBottomColor: '#EEEEF0',
    borderBottomWidth: 1,
    marginBottom: 25,
  },
  addOwnerBtn: {
    marginBottom: 50,
    width: 250,
  },
})
