import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import logo from '@assets/images/logo_color.png'

interface Props {
  content: JSX.Element
}

export const LeftPanel = ({ content }: Props) => {
  return (
    <View style={styles.container}>
      <Image source={logo} style={styles.logo} />
      {content}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100vh',
  },
  logo: {
    width: 101,
    height: 23,
    top: 30,
    left: 30,
    marginBottom: 30,
  },
})
