import React, { FC } from 'react'
import { StyleSheet, TextInput } from 'react-native'
import { COLORS, FONT_TYPES } from '@assets/constants'

export enum InputTypes {
  password = 'password',
  text = 'text',
  picker = 'picker',
  hidden = 'hidden',
  slider = 'slider',
  radioButton = 'radioButton',
}

interface IInputProps {
  onChange: any
  onBlur: any
  value: any
  type: InputTypes
  placeholder?: string
  onChangeText?: any

  [x: string]: unknown
}

export const RNInput: FC<IInputProps> = ({
  type,
  onChange,
  onBlur,
  value,
  onChangeText = null,
  placeholder = '',
  ...rest
}) => {
  if (type === InputTypes.hidden) return null
  return (
    <TextInput
      style={styles.input}
      onBlur={onBlur}
      onChangeText={(val) => {
        onChange(val)
        if (onChangeText) onChangeText(val)
      }}
      value={value}
      secureTextEntry={type === InputTypes.password}
      placeholder={placeholder}
      placeholderTextColor={COLORS.GRAY_700}
      {...rest}
    />
  )
}

const styles = StyleSheet.create({
  input: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: COLORS.GRAY_200,
    borderRadius: 4,
    height: 50,
    fontFamily: FONT_TYPES.FONT_REGULAR,
  },
})
