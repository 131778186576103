import { PermissionContainer } from '@views/components'
import React, { useState } from 'react'
import { StyleSheet, ScrollView } from 'react-native'
import BusinessAccountDrawer from './BusinessAccountDrawer'
import BusinessAccountInfo from './BusinessAccountInfo'

const BusinessAccountPage: React.FC = () => {
  const [issuedMemberCards, setIssuedMemberCards] = useState(false)
  const [depositsMade, setDepositsMade] = useState(false)

  return (
    <ScrollView contentContainerStyle={styles.businessAccContainer}>
      <PermissionContainer>
        <BusinessAccountDrawer
          setIssuedMemberCards={setIssuedMemberCards}
          setDepositsMade={setDepositsMade}
        />
      </PermissionContainer>
      <BusinessAccountInfo issuedMemberCards={issuedMemberCards} depositsMade={depositsMade} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  businessAccContainer: {
    flexDirection: 'column',
    flex: 1,
  },
})

export default BusinessAccountPage
