import { ICreateAccountState } from '@views/contexts/CreateAccount/interface'
import { CreateAccountActions } from '@views/contexts/CreateAccount/actions'

export const CreateAccountReducer = (
  state: ICreateAccountState,
  action: CreateAccountActions
): ICreateAccountState => {
  const reducerActions = {
    SET_LOADING: () => {
      Object.assign(state, { loading: action.payload })
      return { ...state }
    },
    SET_ERROR: () => {
      Object.assign(state, { error: action.payload })
      return { ...state }
    },
    SET_STATE_DATA: () => {
      Object.assign(state, { data: { ...action.payload } })
      return { ...state }
    },
    SET_BASIC_INFO: () => {
      Object.assign(state, {
        data: {
          basicInfo: { ...action.payload },
        },
      })
      return { ...state }
    },
    SET_ADMIN_INFO: () => {
      Object.assign(state, {
        data: {
          adminInfo: { ...action.payload },
        },
      })
      return { ...state }
    },
    SET_OWNER_INFO: () => {
      const currInfo = state.data.beneficialOwnersInfo ? [...state.data.beneficialOwnersInfo] : []
      Object.assign(state, {
        data: {
          beneficialOwnerInfo: [...currInfo, { ...action.payload }],
        },
      })
      return { ...state }
    },
    default: () => state,
  }
  return <ICreateAccountState>(reducerActions[action.type] || reducerActions.default)()
}
