import React from 'react'
import { useForm, FieldError } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { View, ScrollView, StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import {
  AppText,
  ButtonRow,
  Form,
  FormInput,
  Row,
  Button,
  IInputProps,
  openModal,
  closeModal,
} from '@views/components'
import { BeneficialOwnerEmailsSchema, IBeneficialOwnersFields } from '@views/schemas'
import { useSystemConfig } from '@views/hooks'

import { COLORS } from '@assets/constants'

type TProps = {
  setShowForm: (value: boolean) => any
  onSubmit: (data: any) => any
  onBack: () => any
}

const BeneficialOwnersEmail: React.FC<TProps> = ({ setShowForm, onSubmit, onBack }) => {
  const { isSmallDevice, isMediumDevice, isLargeDevice } = useSystemConfig()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BeneficialOwnerEmailsSchema),
  })

  const onSendEmailsClick = (data: any) => {
    openModal({
      dismiss: false,
      type: 'success',
      title: 'Success',
      subtitle: 'Your emails have been sent.',
      buttons: [
        <Button
          style={!isSmallDevice ? styles.modalButton : styles.modalButtonSmall}
          type="primary"
          text="Continue"
          onPress={() => {
            onSubmit(data)
            closeModal()
          }}
        />,
      ],
    })
  }

  const renderFieldView = (field: IInputProps, num: number) => {
    return (
      <View style={!isMediumDevice ? styles.fieldWrapper : null}>
        <AppText bold style={styles.labelText}>
          Beneficial owner/administrative authority {num}
        </AppText>
        <FormInput
          name={field.name}
          label={field.label}
          type={field.type}
          control={control}
          error={errors[field.name] as unknown as FieldError}
          placeholder={field.placeholder}
        />
      </View>
    )
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <AppText style={styles.title} bold={true}>
          Account Creation
        </AppText>
        <AppText style={styles.subTitle1}>
          Create an account to start using Players Health Services.
        </AppText>
        <AppText bold style={styles.subTitle2}>
          Organization info /{' '}
          <AppText bold style={{ color: COLORS.GRAY_500 }}>
            Notify beneficial owners
          </AppText>
        </AppText>
        <AppText style={styles.subTitle2}>
          We need to collect information about each beneficial owner or administrative authority
          (for non-profits). We will send an email and require them to enter information in order
          for the business account to be created.
        </AppText>
        <AppText style={styles.subTitle2}>
          Know your beneficial owner's information? You can{' '}
          <TouchableOpacity onPress={() => setShowForm(true)}>
            <AppText style={styles.link}>add it yourself.</AppText>
          </TouchableOpacity>
        </AppText>
        <Form
          buttonLabel="Send Emails"
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          onBack={onBack}
          showButtons={false}>
          <FormInput
            name={IBeneficialOwnersFields.atLeastOne.name}
            type={IBeneficialOwnersFields.atLeastOne.type}
            error={errors[IBeneficialOwnersFields.atLeastOne.name] as unknown as FieldError}
            control={control}
          />
          <Row
            style={
              isMediumDevice
                ? styles.fieldCol
                : isLargeDevice
                ? styles.fieldRowSmall
                : styles.fieldRow
            }>
            {renderFieldView(IBeneficialOwnersFields.email1, 1)}
            {renderFieldView(IBeneficialOwnersFields.email2, 2)}
          </Row>
          <Row
            style={
              isMediumDevice
                ? styles.fieldCol
                : isLargeDevice
                ? styles.fieldRowSmall
                : styles.fieldRow
            }>
            {renderFieldView(IBeneficialOwnersFields.email3, 3)}
            {renderFieldView(IBeneficialOwnersFields.email4, 4)}
          </Row>
        </Form>
      </View>
      <ButtonRow onSubmit={handleSubmit(onSendEmailsClick)} onBack={onBack} />
    </ScrollView>
  )
}

export default BeneficialOwnersEmail

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    paddingTop: 32,
    paddingBottom: 6,
  },
  subTitle1: {
    fontSize: 16,
    maxWidth: 850,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
  link: {
    textDecorationLine: 'underline',
  },
  fieldRow: {
    justifyContent: 'space-between',
    marginRight: 140,
    marginBottom: 45,
  },
  fieldRowSmall: {
    justifyContent: 'space-between',
    marginRight: 0,
  },
  fieldCol: {
    flexDirection: 'column',
  },
  labelText: {
    fontSize: 16,
    marginBottom: 20,
  },
  fieldWrapper: {
    width: '45%',
  },
  modalButton: {
    width: 400,
  },
  modalButtonSmall: {
    maxWidth: 200,
  },
})
