import { ModalHandles } from './types'

class ModalRef {
  private snackabar: ModalHandles | null = null

  get ref() {
    return this.snackabar
  }

  set ref(newRef) {
    this.snackabar = newRef
  }
}

export default new ModalRef()
