/* eslint-disable no-console */
import { ScrollView, StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import uploadIcon from '@assets/images/upload-icon.png'
import acceptedIcon from '@assets/images/accepted.png'
import infoIcon1 from '@assets/images/info-icon1.png'
import infoIcon2 from '@assets/images/info-icon2.png'
import React, { useCallback, useState } from 'react'
import failedIcon from '@assets/images/failed.png'
import removeIcon from '@assets/images/remove.png'
import retryIcon from '@assets/images/retry.png'
import { useDropzone } from 'react-dropzone'
import { AppText, Row, Button } from '@views/components'
import { useSystemConfig } from '@views/hooks'
import { COLORS } from '@assets/constants'

// @TODO: use this for later implementation
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fileTypes = ['PNG', 'JPEG']

const BusinessDocuments = ({ navigation }: CreateAccountStackScreenProps<'businessDocuments'>) => {
  const [uploadedFiles, setUploadedFiles] = useState<object[]>([])
  const { isSmallDevice } = useSystemConfig()
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader()

        // @TODO: use this for later implementation
        // @ts-ignore
        reader.onabort = () => console.log('file reading was aborted')
        // @ts-ignore
        reader.onerror = () => console.log('file reading has failed')
        // @ts-ignore
        reader.onloadstart = () => console.log('it is loading')
        reader.onload = () => {
          // @ts-ignore
          // @TODO: use this for later implementation
          const binaryStr = reader.result
          const mbSize = file.size / (1024 * 1024)

          // @ts-ignore
          // @TODO: use this for later implementation
          if (mbSize > 5 || uploadedFiles.length >= 3) console.log('the file is too big')
          else if (file.type !== 'image/png' && file.type !== 'image/jpeg')
            console.log('Invalid type')
          else setUploadedFiles((files) => [...files, ...acceptedFiles])
        }
        reader.readAsArrayBuffer(file)
      })
    },
    [uploadedFiles, setUploadedFiles]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  })

  const remove = (idx: number) => {
    const temp = [...uploadedFiles]
    temp.splice(idx, 1)
    setUploadedFiles([...temp])
  }

  const retry = () => {
    /**
      @todo: HERE GOES THE RETRY CODE
    **/
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <AppText style={styles.title} bold={true}>
          Account Creation
        </AppText>
        <AppText style={styles.subTitle1}>Upload documents about your organization.</AppText>
        <AppText bold style={styles.subTitle2}>
          Organization info /{' '}
          <AppText bold style={{ color: COLORS.GRAY_500 }}>
            Documents
          </AppText>
        </AppText>
        <AppText style={styles.instructions}>Upload documents from list</AppText>
        <View
          style={styles.uploadAreaContainer}
          {...getRootProps({
            // @ts-ignore
            // @TODO: use this for later implementation
            onClick: (event) => console.log(event),
            role: 'button',
            'aria-label': 'drag and drop area',
            className: 'dropzone',
          })}>
          <input {...getInputProps()} />
          <View style={styles.uploadImageContainer}>
            <Image style={styles.uploadImage} source={uploadIcon} />
            {uploadedFiles.length === 0 ? (
              <AppText style={{ textAlign: 'center' }}>
                Drag and drop or upload your files in PNG or JPEG
              </AppText>
            ) : uploadedFiles.length === 3 ? (
              <AppText>Documents uploaded</AppText>
            ) : (
              <AppText>Documents uploading...</AppText>
            )}
          </View>
        </View>
        <AppText style={styles.maxSizeText}>Max file size: 5 MB</AppText>

        <View style={styles.statusContainer}>
          <AppText style={styles.subTitle3}>Documents list</AppText>
          {uploadedFiles.length === 0 ? (
            <Image style={styles.infoIcon} source={infoIcon1} />
          ) : (
            <Image style={styles.infoIcon} source={infoIcon2} />
          )}
        </View>

        <AppText style={styles.documents}>- Certificate of incorporation</AppText>
        <AppText style={styles.documents}>- 990 Tax Form</AppText>
        <AppText style={styles.documents}>- Bylaw</AppText>
        <AppText style={styles.subTitle3}>Uploaded</AppText>
        <AppText style={styles.uploadStatus}>{uploadedFiles.length} of 3 uploaded</AppText>

        <View style={styles.uploadsContainer}>
          {uploadedFiles.map((element: any, index: number) => {
            return (
              <View key={index} style={styles.uploadContainer}>
                <View style={styles.statusContainer}>
                  {/*@TODO implement retry functionality */}
                  {true ? (
                    <Image style={styles.statusIcon} source={acceptedIcon} />
                  ) : (
                    <Image style={styles.statusIcon} source={failedIcon} />
                  )}
                  <AppText> {element.name} </AppText>
                </View>

                <View style={styles.statusContainer}>
                  {/*@TODO implement retry functionality */}
                  {true ? (
                    <AppText> Uploaded </AppText>
                  ) : (
                    <View style={styles.statusContainer}>
                      <AppText style={{ color: COLORS.LIGHT_RED }}> Failed </AppText>
                      <TouchableOpacity
                        onPress={() => {
                          retry()
                        }}>
                        <Image style={styles.statusIcon} source={retryIcon} />
                      </TouchableOpacity>
                    </View>
                  )}
                  <TouchableOpacity
                    onPress={() => {
                      remove(index)
                    }}>
                    <Image style={styles.statusIcon} source={removeIcon} />
                  </TouchableOpacity>
                </View>
              </View>
            )
          })}
        </View>
      </View>
      {uploadedFiles.length === 3 ? (
        <Row style={styles.buttonWrapper}>
          <Button
            style={!isSmallDevice ? styles.button : {}}
            onPress={() => navigation.navigate('fundAccount')}
            type="primary"
            text="Submit"
          />
        </Row>
      ) : null}
    </ScrollView>
  )
}

export default BusinessDocuments

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.WHITE,
    paddingHorizontal: 30,
    paddingBottom: 30,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  uploadAreaContainer: {
    backgroundColor: COLORS.GRAY_200,
    borderColor: COLORS.BACKGROUND,
    justifyContent: 'center',
    borderStyle: 'dashed',
    alignItems: 'center',
    borderRadius: 10,
    display: 'flex',
    borderWidth: 2,
    width: '100%',
    height: 150,
  },
  uploadImageContainer: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },
  uploadImage: {
    marginBottom: 10,
    resizeMode: 'contain',
    height: 50,
    width: 50,
  },
  statusContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  uploadsContainer: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },
  uploadContainer: {
    justifyContent: 'space-between',
    borderColor: COLORS.BACKGROUND,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 10,
    display: 'flex',
    borderWidth: 2,
    width: '100%',
    marginTop: 15,
    padding: 20,
  },
  infoIcon: {
    resizeMode: 'contain',
    marginLeft: 15,
    height: 20,
    width: 20,
  },
  statusIcon: {
    resizeMode: 'contain',
    marginLeft: 20,
    height: 20,
    width: 20,
  },
  dropArea: {
    backgroundColor: COLORS.BACKGROUND,
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderColor: COLORS.GRAY_200,
    alignItems: 'center',
    borderRadius: 10,
    display: 'flex',
    width: '100%',
    height: 150,
  },
  maxSizeText: {
    color: COLORS.GRAY_500,
    marginTop: 10,
    maxWidth: 850,
    fontSize: 10,
  },
  title: {
    fontSize: 24,
    paddingTop: 32,
    paddingBottom: 6,
  },
  subTitle1: {
    maxWidth: 850,
    fontSize: 16,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
  subTitle3: {
    fontWeight: 'bold',
    marginTop: 32.5,
    marginBottom: 5,
    maxWidth: 850,
    fontSize: 14,
  },
  instructions: {
    marginTop: 32.5,
    marginBottom: 5,
    maxWidth: 850,
    fontSize: 12,
  },
  uploadStatus: {
    marginVertical: 5,
    color: COLORS.GRAY_700,
    maxWidth: 850,
    fontSize: 12,
  },
  documents: {
    marginVertical: 3,
    maxWidth: 850,
    fontSize: 14,
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  button: {
    maxWidth: 270,
  },
})
