import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { AppText } from '@views/components'
import { COLORS } from '@assets/constants'
import { currencyFormatter } from '@views/utils'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { AntDesign } from '@expo/vector-icons'

type Props = {
  showDepositsTab: boolean
  issuedMemberCards: boolean
  depositsMade: boolean
}

const DepositsTab = ({ depositsMade }: any) => {
  if (!depositsMade)
    return (
      <View style={styles.clubTeamInfocontainer}>
        <View>
          <AppText style={styles.noElements}>No deposits made yet.</AppText>
        </View>
      </View>
    )
  return (
    <View style={styles.clubTeamInfocontainer}>
      <View style={styles.subInfoContainer}>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Sender</AppText>
          <AppText style={styles.member}>Sean Rodriguez</AppText>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Amount</AppText>
          <AppText style={styles.amount}>{currencyFormatter(20000)}</AppText>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Status</AppText>
          <AppText style={styles.status}>Processed</AppText>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Date</AppText>
          <AppText style={styles.date}>04/13/22</AppText>
        </View>
        <AppText style={styles.details}>View Details</AppText>
      </View>
    </View>
  )
}

const MemberCards = ({ issuedMemberCards }: any) => {
  const [showDetails, setShowDetails] = useState(false)

  if (!issuedMemberCards)
    return (
      <View style={styles.clubTeamInfocontainer}>
        <View>
          <AppText style={styles.noElements}>You have not issued any member cards.</AppText>
        </View>
      </View>
    )
  return (
    <View style={styles.clubTeamInfocontainer}>
      <View style={styles.subInfoContainer}>
        <View style={styles.buttonsContainer}>
          <View />

          <View style={styles.memberCardHeader}>
            <TouchableOpacity style={{ marginRight: 8 }}>
              <AntDesign name="lock1" size={16} color={COLORS.GRAY_700} />
            </TouchableOpacity>
            <TouchableOpacity>
              <AntDesign name="closesquare" size={16} color={COLORS.GRAY_700} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Card</AppText>
          <AppText style={styles.member}>Barbara Fuller</AppText>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Amount</AppText>
          <AppText style={styles.amount}>{currencyFormatter(20000)}</AppText>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Status</AppText>
          <AppText style={styles.status}>Active</AppText>
        </View>
        {showDetails ? (
          <>
            <View style={styles.clubInfoRow}>
              <AppText style={styles.tabHeader}>Cardholder name</AppText>
              <AppText style={styles.status}>Tyrre Burks</AppText>
            </View>
            <View style={styles.clubInfoRow}>
              <AppText style={styles.tabHeader}>Card number</AppText>
              <AppText style={styles.status}>4539071912363424</AppText>
            </View>
            <View style={styles.clubInfoRow}>
              <AppText style={styles.tabHeader}>Cardholder role</AppText>
              <AppText style={styles.status}>Board member</AppText>
            </View>
            <View style={styles.clubInfoRow}>
              <AppText style={styles.tabHeader}>Department</AppText>
              <AppText style={styles.status}>Player's Health</AppText>
            </View>
            <View style={styles.clubInfoRow}>
              <AppText style={styles.tabHeader}>Date created</AppText>
              <AppText style={styles.status}>07/03/21</AppText>
            </View>
          </>
        ) : null}
        <AppText style={styles.details} onPress={() => setShowDetails(!showDetails)}>
          {showDetails ? 'Hide' : 'View'} Details
        </AppText>
      </View>
    </View>
  )
}

const AccountInformation: React.FC<Props> = ({
  showDepositsTab,
  issuedMemberCards,
  depositsMade,
}) => {
  return showDepositsTab ? (
    <DepositsTab depositsMade={depositsMade} />
  ) : (
    <MemberCards issuedMemberCards={issuedMemberCards} />
  )
}

const styles = StyleSheet.create({
  clubTeamInfocontainer: {
    width: '100%',
    minWidth: 352,
    backgroundColor: COLORS.WHITE,
  },
  noElements: {
    fontSize: 16,
    fontWeight: '400',
  },
  clubInfoHeader: {
    paddingTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  subInfoContainer: {
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    padding: 24,
    marginBottom: 16,

    shadowColor: COLORS.GRAY_700,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 10,
  },
  tabHeader: {
    fontSize: 14,
    fontWeight: '600',
  },
  details: {
    paddingTop: 20,
    paddingVertical: 10,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '600',
  },
  member: {
    fontSize: 14,
    fontWeight: '800',
  },
  amount: {
    fontSize: 14,
    fontWeight: '300',
  },
  role: {
    borderRadius: 8,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: COLORS.BLUE_300,
    fontSize: 14,
    fontWeight: '300',
  },
  status: {
    fontWeight: '300',
    fontSize: 14,
  },
  sender: {
    fontSize: 14,
    fontWeight: '600',
  },
  date: {
    fontSize: 14,
    fontWeight: '300',
  },
  clubInfoRow: {
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    borderBottomWidth: 3,
    borderBottomColor: COLORS.GRAY_200,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  memberCardHeader: {
    flexDirection: 'row',
  },
})

export default AccountInformation
