import React from 'react'
import { AppText, Button, FormInput, InputTypes } from '@views/components'
import { View, ScrollView, StyleSheet } from 'react-native'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { onlyNumberRegex, moneyRegex } from '@assets/constants'
import * as yup from 'yup'

const transferFundsSchema = yup.object().shape({
  selectCard: yup.string().required('Required'),
  routingOrABANumber: yup.string().required('Required').matches(onlyNumberRegex, 'Invalid format'),
  accountNumber: yup.string().required('Required').matches(onlyNumberRegex, 'Invalid format'),
  amount: yup.string().required('Required').matches(moneyRegex, 'Invalid format'),
  comments: yup.string(),
})

export const TransferFunds = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(transferFundsSchema),
  })

  const onSubmit = (data: any) => console.log(data)

  return (
    <>
      <View style={styles.header}>
        <AppText bold style={styles.title}>
          Transfer Funds
        </AppText>
      </View>
      <ScrollView>
        <FormInput
          type={InputTypes.picker}
          items={[
            { label: 'item1', value: 'a' },
            { label: 'item2', value: 'b' },
            { label: 'item3', value: 'c' },
          ]}
          name={'selectCard'}
          label={'Select Card'}
          control={control}
          error={errors.selectCard as unknown as FieldError}
          placeholder={'Select Card'}
        />
        <FormInput
          type={InputTypes.text}
          name={'routingOrABANumber'}
          label={'Routing or ABA number'}
          control={control}
          error={errors.routingOrABANumber as unknown as FieldError}
          placeholder={'Routing or ABA number'}
        />
        <FormInput
          type={InputTypes.text}
          name={'accountNumber'}
          label={'Account number'}
          control={control}
          error={errors.accountNumber as unknown as FieldError}
          placeholder={'Account number'}
        />
        <FormInput
          type={InputTypes.text}
          name={'amount'}
          label={'Amount'}
          control={control}
          error={errors.amount as unknown as FieldError}
          placeholder={'Enter amount'}
        />
        <FormInput
          type={InputTypes.text}
          name={'comments'}
          label={'Comments'}
          control={control}
          error={errors.comments as unknown as FieldError}
          placeholder={'Enter comments'}
        />

        <Button onPress={handleSubmit(onSubmit)} text="Transfer Funds" />
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  title: { fontSize: 24, marginBottom: 8 },
  closeIcon: { width: 20, height: 20, resizeMode: 'contain' },
  header: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
})
