import React from 'react'
import { View, TouchableOpacity, Image, StyleSheet, ScrollView } from 'react-native'
import { AppText, closeModal, openModal } from '@views/components'

import { COLORS } from '@assets/constants'
import trasferFundsCard from '@assets/images/transfer-funds-card.png'
import internalTransferCard from '@assets/images/internal-transfer-card.png'

import closeIcon from '@assets/images/close-icon.png'
import { TransferFunds } from '../TransferFunds'
import { InternalTransfer } from '../InternalTransfer'

export const CreatePayment = () => {
  const openTransferFunds = () => {
    openModal({
      content: <TransferFunds />,
      contentStyle: styles.fundsModal,
    })
  }
  const openInternalTransfer = () => {
    openModal({
      content: <InternalTransfer />,
      contentStyle: styles.fundsModal,
    })
  }
  return (
    <>
      <View style={styles.headerContainer}>
        <View>
          <AppText bold style={styles.title}>
            Create A Payment
          </AppText>
          <AppText style={styles.subtitle}>Choose payment type</AppText>
        </View>
        <TouchableOpacity onPress={closeModal}>
          <Image source={closeIcon} style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={styles.optionsContainer}>
        <TouchableOpacity style={[styles.imageContainer, styles.space]} onPress={openTransferFunds}>
          <Image source={trasferFundsCard} style={styles.optionsImage} />
        </TouchableOpacity>
        <TouchableOpacity onPress={openInternalTransfer} style={styles.imageContainer}>
          <Image source={internalTransferCard} style={styles.optionsImage} />
        </TouchableOpacity>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  headerContainer: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  title: { fontSize: 24, marginBottom: 8 },
  subtitle: { fontSize: 16 },
  closeIcon: { width: 20, height: 20, resizeMode: 'contain' },
  optionsContainer: {
    flexDirection: 'row',
    marginTop: 40,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  optionsImage: { width: 240, height: 200, resizeMode: 'contain' },
  imageContainer: { padding: 20, borderWidth: 1, borderRadius: 8, borderColor: COLORS.GRAY_200 },
  space: { marginRight: 10, marginBottom: 10 },
  fundsModal: { minWidth: undefined, width: '80%', maxHeight: 630 },
})
