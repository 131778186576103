import React, { useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import { AppText, openModal, DetailsModal, PermissionContainer } from '@views/components'
import { COLORS } from '@assets/constants'
import activateCard from '@assets/images/activate-card-icon.png'
import createPayment from '@assets/images/create-payment-icon.png'
import issueCard from '@assets/images/issue-card-icon.png'
import makeDeposit from '@assets/images/make-deposit-icon.png'
import infoIcon from '@assets/images/info-icon3.png'
import AccountInformation from './AccountInformation'
import { currencyFormatter, getRoleName } from '@views/utils'
import IssueCard from './IssueCard'
import { CreatePayment } from '@views/components/CreatePayment'
import { useAuth, useSystemConfig } from '@views/hooks'

type Props = {
  issuedMemberCards: any
  depositsMade: any
}

const BusinessAccountInfo: React.FC<Props> = ({ issuedMemberCards, depositsMade }) => {
  const [showDepositsTab, setShowDepositsTab] = useState(false)
  const { isSmallDevice } = useSystemConfig()

  const { user } = useAuth()
  const hasRole = !!user?.role

  const openCreatePaymentModal = () => {
    const containerStyle = isSmallDevice
      ? { height: '90%', width: '90%', maxHeight: 670 }
      : { maxWidth: 670 }

    openModal({
      contentStyle: containerStyle,
      content: <CreatePayment />,
      dismiss: false,
    })
  }

  const details = [
    {
      label: 'Account name',
      value: 'Ohio Rockets Club',
    },
    {
      label: 'Account number',
      value: '97426709',
      isMasked: true,
    },
    {
      label: 'Routing number',
      value: '123456789',
    },
    {
      label: 'Account type',
      value: 'Debit',
    },
  ]

  const onAccountInfoClick = () => {
    openModal({
      dismiss: true,
      title: 'Account Details',
      content: <DetailsModal content={details} />,
    })
  }

  return (
    <View style={styles.clubTeamInfocontainer}>
      <View style={styles.headerContainer}>
        <View style={styles.optionContainer}>
          <AppText style={styles.clubTeamName}>Ohio Rockets Club</AppText>
          <TouchableOpacity onPress={onAccountInfoClick}>
            <Image style={styles.infoIcon} source={infoIcon} />
          </TouchableOpacity>
        </View>
        <View style={styles.teamMainInfo}>
          <AppText style={styles.ownerName}>{user?.first_name}</AppText>
          {hasRole && <AppText style={styles.ownerRole}>{getRoleName(user?.role)}</AppText>}
        </View>
      </View>

      <AppText style={styles.balance}>{currencyFormatter(25000)}</AppText>
      <AppText style={styles.availableBalance}>Available debit balance</AppText>

      <View style={styles.optionsContainer}>
        <TouchableOpacity style={styles.optionContainer} onPress={openCreatePaymentModal}>
          <View style={[{ backgroundColor: COLORS.CYAN_200 }, styles.optionsIconContainer]}>
            <Image style={styles.optionsIcon} source={createPayment} />
          </View>
          <AppText style={styles.optionsTitles}>Create a payment</AppText>
        </TouchableOpacity>

        <PermissionContainer>
          <TouchableOpacity
            onPress={() => {
              openModal({
                dismiss: false,
                content: <IssueCard />,
              })
            }}
            style={styles.optionContainer}>
            <View style={[{ backgroundColor: COLORS.PURPLE_LIGHT }, styles.optionsIconContainer]}>
              <Image style={styles.optionsIcon} source={issueCard} />
            </View>
            <AppText style={styles.optionsTitles}>Issue card</AppText>
          </TouchableOpacity>
        </PermissionContainer>

        <View style={styles.optionContainer}>
          <View style={[{ backgroundColor: COLORS.BLUE_300 }, styles.optionsIconContainer]}>
            <Image style={styles.optionsIcon} source={makeDeposit} />
          </View>
          <AppText style={styles.optionsTitles}>Make a deposit</AppText>
        </View>

        <View style={styles.optionContainer}>
          <View style={[{ backgroundColor: COLORS.ORANGE_LIGHT }, styles.optionsIconContainer]}>
            <Image style={styles.optionsIcon} source={activateCard} />
          </View>
          <AppText style={styles.optionsTitles}>Activate card</AppText>
        </View>
      </View>

      <View style={styles.tabsContainer}>
        <TouchableOpacity
          onPress={() => {
            setShowDepositsTab(false)
          }}>
          <View style={showDepositsTab ? styles.tabContainer : styles.tabContainerSelected}>
            <AppText
              style={[styles.tab, { color: !showDepositsTab ? COLORS.PRIMARY : COLORS.GRAY_500 }]}>
              Member cards
            </AppText>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            setShowDepositsTab(true)
          }}>
          <View style={showDepositsTab ? styles.tabContainerSelected : styles.tabContainer}>
            <AppText
              style={[styles.tab, { color: showDepositsTab ? COLORS.PRIMARY : COLORS.GRAY_500 }]}>
              Deposits
            </AppText>
          </View>
        </TouchableOpacity>
      </View>

      <AccountInformation
        showDepositsTab={showDepositsTab}
        issuedMemberCards={issuedMemberCards}
        depositsMade={depositsMade}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  clubTeamInfocontainer: {
    height: '100%',
    flexGrow: 1,
    padding: 30,
    backgroundColor: COLORS.WHITE,
  },
  headerContainer: {
    paddingBottom: 40,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionsContainer: {
    paddingBottom: 40,
    alignItems: 'center',
    flexDirection: 'row',
  },
  optionContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    marginRight: 24,
  },
  clubTeamName: {
    fontSize: 24,
    fontWeight: '700',
    color: COLORS.GRAY_700,
  },
  infoIcon: {
    marginLeft: 10,
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  teamMainInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  ownerName: {
    fontSize: 14,
    fontWeight: '600',
  },
  balance: {
    fontWeight: '700',
    fontSize: 22,
    marginBottom: 10,
  },
  availableBalance: {
    fontWeight: '600',
    fontSize: 16,
    marginBottom: 30,
  },
  optionsIconContainer: {
    borderRadius: 10,
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionsIcon: {
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  optionsTitles: {
    fontWeight: '500',
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 10,
  },
  tabsContainer: {
    paddingVertical: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  tabContainer: {
    paddingBottom: 2,
    marginVertical: 10,
    marginRight: 20,
  },
  tabContainerSelected: {
    paddingBottom: 2,
    marginVertical: 10,
    marginRight: 20,
    borderBottomWidth: 3,
    borderBottomColor: COLORS.PRIMARY,
  },
  tab: {
    fontSize: 16,
    fontWeight: '600',
  },
  ownerRole: { fontSize: 12 },
})

export default BusinessAccountInfo
