import React, { useState } from 'react'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import BeneficialOwnersForm from './BeneficialOwnersForm'
import BeneficialOwnersEmail from './BeneficialOwnersEmail'

const BeneficialOwners = ({ navigation }: CreateAccountStackScreenProps<'beneficialOwners'>) => {
  const [showForm, setShowForm] = useState(true)

  const onSubmit = () => {
    navigation.navigate('sportInfo')
  }

  const onBack = () => {
    navigation.navigate('adminInfo')
  }

  return (
    <>
      {showForm ? (
        <BeneficialOwnersForm setShowForm={setShowForm} onSubmit={onSubmit} onBack={onBack} />
      ) : (
        <BeneficialOwnersEmail setShowForm={setShowForm} onSubmit={onSubmit} onBack={onBack} />
      )}
    </>
  )
}

export default BeneficialOwners
