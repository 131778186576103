import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'
import { FONT_TYPES, COLORS } from '@assets/constants'

type TProps = {
  bold?: boolean
  semiBold?: boolean
  light?: boolean
  style?: {}
  children?: any
  onPress?: () => void
}

type TFontWeight = { bold?: boolean; semiBold?: boolean; light?: boolean }

const getFontFamily = (fontWeight: TFontWeight): FONT_TYPES => {
  const { bold, semiBold, light } = fontWeight

  if (bold) return FONT_TYPES.FONT_BOLD
  if (semiBold) return FONT_TYPES.FONT_SEMIBOLD
  if (light) return FONT_TYPES.FONT_LIGHT
  return FONT_TYPES.FONT_REGULAR
}

export const AppText: React.FC<TProps> = ({ bold, semiBold, light, style, children, onPress }) => {
  const fontFamily = getFontFamily({ bold, semiBold, light })

  if (onPress)
    return (
      <TouchableOpacity onPress={onPress}>
        <Text style={[{ fontFamily: fontFamily }, style]}>{children}</Text>
      </TouchableOpacity>
    )

  return <Text style={[{ fontFamily: fontFamily }, styles.defaultColor, style]}>{children}</Text>
}

const styles = StyleSheet.create({
  defaultColor: {
    color: COLORS.DARK,
  },
})
