import {
  BeVietnamPro_300Light,
  BeVietnamPro_400Regular,
  BeVietnamPro_600SemiBold,
  BeVietnamPro_700Bold,
} from '@expo-google-fonts/be-vietnam-pro'
import { useFonts } from 'expo-font'

export function useCustomFonts() {
  let [fontsLoaded, error] = useFonts({
    BeVietnamPro_300Light,
    BeVietnamPro_400Regular,
    BeVietnamPro_600SemiBold,
    BeVietnamPro_700Bold,
  })

  return [fontsLoaded, error]
}
