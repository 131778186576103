import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import AuthNavigation from './AuthNavigation'
import MainNavigation from './MainNavigation'
import { LoadingPage } from '@views/pages'
import { useAuth } from '@views/hooks'
import { navigationRef } from '@views/helpers/navigationRef'

const linking = {
  prefixes: [window.location.origin],
}

export default function Navigation() {
  const { isAuthenticated, isLoadingAuth } = useAuth()

  if (isLoadingAuth) return <LoadingPage />

  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      {isAuthenticated ? <MainNavigation /> : <AuthNavigation />}
    </NavigationContainer>
  )
}
