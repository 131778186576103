import React, { createContext, useState, ReactNode } from 'react'

import { Policy, Terms, closeModal, openModal } from '@views/components'

interface DisclaimerContextProps {
  children: ReactNode
}

type DisclaimerArgs = { onAccepted: () => void }

interface DisclaimerContextInfo {
  openDisclaimers: ({ onAccepted }: DisclaimerArgs) => void
  isDisclaimerAccepted: boolean
}

export const DisclaimerContext = createContext<DisclaimerContextInfo>({} as DisclaimerContextInfo)

export function DisclaimerContextProvider({ children }: DisclaimerContextProps) {
  const [isTermsAccepted, setTermsAccepted] = useState(false)
  const [isPolicyAccepted, setPolicyAccepted] = useState(false)

  const handleTerms = ({ onAccepted }: DisclaimerArgs) => {
    openModal({
      dismiss: false,
      content: (
        <Terms
          onAccepted={() => {
            setTermsAccepted(true)
            handlePolicy({ onAccepted })
          }}
        />
      ),
    })
  }

  const handlePolicy = ({ onAccepted }: DisclaimerArgs) => {
    openModal({
      dismiss: false,
      content: (
        <Policy
          onAccepted={() => {
            setPolicyAccepted(true)
            onAccepted()
            closeModal()
          }}
        />
      ),
    })
  }

  const openDisclaimers = ({ onAccepted }: DisclaimerArgs) => {
    if (isTermsAccepted) {
      handlePolicy({ onAccepted })
      return
    }
    handleTerms({ onAccepted })
  }

  return (
    <DisclaimerContext.Provider
      value={{ openDisclaimers, isDisclaimerAccepted: isTermsAccepted && isPolicyAccepted }}>
      {children}
    </DisclaimerContext.Provider>
  )
}
