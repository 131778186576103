import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import { Camera as ExpoCamera } from 'expo-camera'

import { AppText, Button, Camera, KYCModal } from '@views/components'
import { useSystemConfig } from '@views/hooks'

import logo from '@assets/images/logo_color.png'
import ImgPlaceholder from '@assets/images/img_placeholder.png'

enum IDTypes {
  PASSPORT = 'PASSPORT',
  LICENSE = 'LICENSE',
}

const ImgCont = ({ imgSrc, title, setPreview }: any) => (
  <TouchableOpacity
    disabled={imgSrc === ImgPlaceholder}
    onPress={() => {
      setPreview({ imgSrc, side: title })
    }}>
    <View style={styles.imgTitle}>
      <Image source={{ uri: imgSrc }} style={styles.image} />
      <AppText>{title}</AppText>
    </View>
  </TouchableOpacity>
)

const Content = ({ setCamera, takePicture, preview, setPreview, setIsFront }: any) => {
  if (preview === null) return <Camera setCamera={setCamera} takePicture={takePicture} />
  return (
    <View>
      <View style={styles.cameraContainer}>
        <Image source={{ uri: preview.imgSrc }} style={styles.camera} />
      </View>
      <View style={styles.buttonContainer}>
        <Button
          type={'secondary'}
          onPress={() => {
            setIsFront(preview.side !== 'Back')
            setPreview(null)
          }}
          style={styles.marginBtm}
          text={'Retake'}
        />
        <Button
          type={'secondary'}
          onPress={() => {
            setPreview(null)
          }}
          text={'Back'}
        />
      </View>
    </View>
  )
}

const PersonalDocuments = () => {
  const [hasPermission, setHasPermission] = useState(false)
  const [IDType, setIDType] = useState<IDTypes | null>(null)
  const [camera, setCamera] = useState<ExpoCamera | null>(null)
  const [front, setFront] = useState<string | null>(null)
  const [back, setBack] = useState<string | null>(null)
  const [preview, setPreview] = useState<{ imgSrc: string; side: string } | null>(null)
  const [isFront, setIsFront] = useState<boolean>(true)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const { isMobile } = useSystemConfig()

  const isWeb = isMobile()

  useEffect(() => {
    ;(async () => {
      if (isWeb) {
        const { status } = await ExpoCamera.requestCameraPermissionsAsync()
        setHasPermission(status === 'granted')
      }
      return
    })()
    return
  }, [])

  if (!isWeb)
    return (
      <View style={styles.modalStyle}>
        <KYCModal />
      </View>
    )

  if (!hasPermission)
    return (
      <View>
        <AppText style={styles.paddedText}>Permission for camera use has not been given.</AppText>
      </View>
    )

  const takePicture = async () => {
    if (camera) {
      const data = await camera.takePictureAsync()
      if (isFront) {
        setFront(data.uri)
        setIsComplete(isFront && IDType === IDTypes.PASSPORT)
        setIsFront(IDType !== IDTypes.LICENSE)
      } else
        setBack(() => {
          setIsComplete(front !== null && IDType === IDTypes.LICENSE)
          return data.uri
        })
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleView}>
        <Image source={logo} style={styles.logo} />
        <AppText style={styles.title} bold>
          {IDType === null
            ? 'Would you like to upload a photograph of your passport or driver license?'
            : IDType === IDTypes.PASSPORT
            ? 'Please take a photo of the information page of your passport'
            : `Please take a photo of the ${isFront ? 'front' : 'back'} side of your license.`}
        </AppText>
        {IDType === null && (
          <>
            <View style={styles.btnRow}>
              <Button
                style={styles.marginBtm}
                type={'secondary'}
                onPress={() => setIDType(IDTypes.PASSPORT)}
                text={'Passport'}
              />
              <Button
                style={styles.marginBtm}
                type={'secondary'}
                onPress={() => setIDType(IDTypes.LICENSE)}
                text={'License'}
              />
            </View>
          </>
        )}
      </View>
      {IDType !== null && (
        <>
          <Content
            setCamera={setCamera}
            takePicture={takePicture}
            preview={preview}
            setPreview={setPreview}
            setIsFront={setIsFront}
          />
          <View style={styles.imageContainer}>
            <ImgCont
              imgSrc={front !== null ? front : ImgPlaceholder}
              title={IDType === IDTypes.PASSPORT ? 'Info Page' : 'Front'}
              setPreview={setPreview}
            />
            {IDType === IDTypes.LICENSE && (
              <ImgCont
                imgSrc={back !== null ? back : ImgPlaceholder}
                title={'Back'}
                setPreview={setPreview}
              />
            )}
          </View>
          <Button
            onPress={() => console.log('pics submit')}
            text={'Submit'}
            disabled={!isComplete}
          />
        </>
      )}
    </View>
  )
}

export default PersonalDocuments

const styles = StyleSheet.create({
  paddedText: {
    padding: 20,
  },
  container: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
  },
  logo: {
    width: 101,
    height: 23,
    marginBottom: 15,
  },
  button: {
    flex: 0.1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  titleView: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  btnRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '90vw',
    marginTop: 120,
  },
  marginBtm: {
    marginBottom: 15,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: 100,
    width: '90vw',
    marginBottom: 20,
  },
  image: {
    width: 130,
    height: '100%',
  },
  imgTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  camera: {
    width: '100%',
    height: '100%',
  },
  cameraContainer: {
    height: '60vw',
    width: '90vw',
  },
  buttonContainer: {
    marginTop: 20,
  },
  modalStyle: {
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '75vh',
  },
})
