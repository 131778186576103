import { AuthenticateUserData, UserRepository } from '@domain/repositories/UserRepository'
import { HttpRepository } from '@domain/repositories/HttpRepository'
import { AdminInfoToUserDTO } from '@microservices/dto/UserDTO'
import { User } from '@domain/models'
import { IAdminInfo, IBeneficialOwnersInfo } from '@views/schemas'

export const userRepository = (client: HttpRepository): UserRepository => ({
  // @TODO: update
  createAdminUser: async (adminInfo: IAdminInfo) => {
    const user = AdminInfoToUserDTO(adminInfo)
    return await client.post('user/admin', user)
  },
  authenticateUser: async (data: AuthenticateUserData) => {
    const response = await client.post<User>('user/authenticate', data)
    return response?.data
  },

  // @TODO: update
  createAdminWithDisclosures: async (adminInfo: IAdminInfo) => {
    return await client.post('user/admin/disclosures', adminInfo)
  },

  createBeneficialOwners: async (owner: IBeneficialOwnersInfo, businessId: string) => {
    const body = {
      user: owner,
      relationship_type: 'BENEFICIAL_OWNER_OF',
      additional_data: {
        percent_ownership: owner.percentageOwnership,
      },
    }
    await client.post(`user/owners/${businessId}`, body)
  },
})
