import React, { FC, useState } from 'react'
import { StyleSheet } from 'react-native'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from 'react-native-simple-radio-button'

import { COLORS, FONT_TYPES } from '@assets/constants'
import { IInputItems } from '@views/components'

interface IRadioButtonProps {
  onChange: any
  value: any
  items: IInputItems[]
}

export const RNRadioButton: FC<IRadioButtonProps> = ({ onChange, items }) => {
  const [selected, setSelected] = useState(-1)

  const onPress = (itemValue: any, idx: number) => {
    setSelected(idx)
    onChange(itemValue)
  }

  return (
    <RadioForm animation={false}>
      {items.map((item, idx) => {
        return (
          <RadioButton key={`radio-btn-${idx}`} style={styles.radioButton}>
            <RadioButtonInput
              obj={item}
              index={idx}
              isSelected={idx === selected}
              onPress={(itemValue) => onPress(itemValue, idx)}
              buttonInnerColor={COLORS.CYAN}
              buttonOuterColor={COLORS.CYAN}
              buttonSize={7}
              buttonStyle={styles.input}
            />
            <RadioButtonLabel
              obj={item}
              index={idx}
              onPress={(itemValue) => onPress(itemValue, idx)}
              labelStyle={styles.label}
            />
          </RadioButton>
        )
      })}
    </RadioForm>
  )
}

const styles = StyleSheet.create({
  radioButton: {
    marginBottom: 10,
  },
  input: {
    marginRight: 10,
    borderWidth: 1.5,
  },
  label: {
    fontSize: 14,
    fontFamily: FONT_TYPES.FONT_REGULAR,
  },
})
