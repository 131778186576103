import { Dispatch } from 'react'
import { IAdminInfo, IBasicInfo, IBeneficialOwnersInfo } from '@views/schemas'
import { businessService } from '@domain/service/BusinessService'
import { businessRepo } from '@microservices/instances/BusinessRepo'
import { userService } from '@domain/service/UserService'
import { userRepo } from '@microservices/instances/UserRepo'

export enum ActionType {
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_BASIC_INFO = 'SET_BASIC_INFO',
  SET_ADMIN_INFO = 'SET_ADMIN_INFO',
  SET_OWNER_INFO = 'SET_OWNER_INFO',
  SET_STATE_DATA = 'SET_STATE_DATA',
}

export interface IAction {
  type: ActionType
  payload?: any
}

export const postBasicInfoAction = async (
  payload: IBasicInfo,
  dispatchCallback: Dispatch<IBasicInfo>
): Promise<void> => {
  const res = await businessService(businessRepo).createBusiness(payload)
  dispatchCallback({ ...payload, orgId: res.data.id })
}

export const postAdminInfoAction = async (
  payload: IAdminInfo,
  dispatchCallback: Dispatch<IAdminInfo>
): Promise<any> => {
  const res = await userService(userRepo).createAdminUser(payload)
  const adminInfo = { ...payload, id: res.data.admin.id }
  dispatchCallback(adminInfo)
  return adminInfo
}

export const postOwnerInfoAction = async (
  payload: {
    owner: IBeneficialOwnersInfo
    businessId: string
  },
  dispatchCallback: Dispatch<IBeneficialOwnersInfo>
): Promise<void> => {
  const res = await userService(userRepo).createBeneficialOwners(payload.owner, payload.businessId)
  dispatchCallback({ ...payload.owner, id: res.id })
}

export type CreateAccountActions = IAction
