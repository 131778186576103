import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {
  BusinessPageMobile,
  TransactionsPage,
  SettingsPage,
  OtherPage,
  PersonalDocuments,
} from '@views/pages'
import {
  BUSINESS_ACCOUNT_ROUTE,
  COLORS,
  mainNavPagesMobile,
  OTHER_ROUTE,
  SETTINGS_ROUTE,
  TRANSACTIONS_ROUTE,
  PERSONAL_DOCUMENTS_ROUTE,
} from '@assets/constants'
import { AppText } from '@views/components'
import { View, Image, StyleSheet } from 'react-native'
import logo from '@assets/images/logo_color.png'
import { useAuth } from '@views/hooks'
import { getRoleName } from '@views/utils'
import { createStackNavigator } from '@react-navigation/stack'

const Tab = createBottomTabNavigator()
const Stack = createStackNavigator()

export const MobileNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen component={Tabs} name="MainTabs" />
      <Stack.Screen component={PersonalDocuments} name={PERSONAL_DOCUMENTS_ROUTE} />
    </Stack.Navigator>
  )
}

const Tabs = () => (
  <Tab.Navigator
    screenOptions={({ route }) => ({
      tabBarStyle: styles.tabStyle,
      tabBarItemStyle: styles.tabItemStyle,
      header: Header,
      tabBarIcon: ({ color }) => {
        const activeTab = mainNavPagesMobile.filter((tab) => tab.page === route.name)[0]
        return activeTab.getIcon(color)
      },
      tabBarActiveTintColor: COLORS.PRIMARY,
      tabBarInactiveTintColor: COLORS.GRAY_500,
    })}>
    <Tab.Screen name={BUSINESS_ACCOUNT_ROUTE} component={BusinessPageMobile} />
    <Tab.Screen name={TRANSACTIONS_ROUTE} component={TransactionsPage} />
    <Tab.Screen name={SETTINGS_ROUTE} component={SettingsPage} />
    <Tab.Screen name={OTHER_ROUTE} component={OtherPage} />
  </Tab.Navigator>
)

const Header = () => {
  const { user } = useAuth()
  const hasRole = !!user?.role
  return (
    <View style={styles.headerContainer}>
      <Image source={logo} style={styles.logo} />
      <View>
        <AppText semiBold>{user?.first_name}</AppText>
        {hasRole && <AppText style={styles.role}>{getRoleName(user?.role)}</AppText>}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  tabStyle: {
    height: 60,
    borderTopColor: 'transparent',
  },
  tabItemStyle: { padding: 10 },
  headerContainer: {
    backgroundColor: COLORS.WHITE,
    padding: 16,
    borderBottomWidth: 2,
    borderColor: COLORS.GRAY_200,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: { width: 130, height: 40, resizeMode: 'contain' },
  role: { color: COLORS.GRAY_700 },
})
