import React, { createContext, ReactNode, useEffect, useState, useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0_CLIENT_ID, AUTH_TOKEN_KEY } from '@assets/constants'
import { LocalStorage } from '@microservices/instances/LocalStorage'
import { userService } from '@domain/service/UserService'
import { userRepo } from '@microservices/instances/UserRepo'
import { User } from '@domain/models'

/** @TODO enable once the create forms integration are ready */
// import { getNavRef } from '@views/helpers/navigationRef'

interface AuthContextInfo {
  user?: User
  logout: () => void
  login: (redirectUri?: string) => Promise<void>
  isLoadingAuth: boolean
  isAuthenticated: boolean
}

interface AuthContextProps {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextInfo)
const localStorage = new LocalStorage()

export function AuthContextProvider({ children }: AuthContextProps) {
  /** @TODO enable once the create forms integration are ready */
  // const navigationRef = getNavRef()

  const {
    logout: auth0Logout,
    loginWithRedirect,
    isLoading: isLoadingAuth0,
    isAuthenticated: isAuthenticatedAuth0,
    user: auth0User,
    getAccessTokenSilently,
  } = useAuth0()

  const [user, setUser] = useState<User>({} as User)
  const [isAuthenticatingApi, setIsAuthenticating] = useState(false)
  const [isAuthenticatatedApi, setIsAuthenticatedApi] = useState(false)

  const logout = useCallback(async () => {
    auth0Logout({ returnTo: window.location.origin, client_id: AUTH0_CLIENT_ID })
    await localStorage.removeItem(AUTH_TOKEN_KEY)
  }, [auth0Logout])

  const authenticateToApi = useCallback(async () => {
    setIsAuthenticating(true)
    try {
      const token = await getAccessTokenSilently()
      await localStorage.setItem(AUTH_TOKEN_KEY, token)

      const res = await userService(userRepo).authenticateUser({
        email: auth0User!.email!,
        email_verified: auth0User?.email_verified!,
        first_name: auth0User?.name,
      })

      if (res) setUser({ ...res, role: auth0User?.playershealth_roles[0] })

      /** @TODO this is the logic in case user has not complete the create forms, should be enabled once the forms are full integrated */
      // const isCreateFlowComplete = res?.business
      // if (!isCreateFlowComplete) {
      //   setIsAuthenticating(false)
      //   await sleep(300)
      //   navigationRef?.current?.navigate('createAccount', {
      //     screen: 'basicInfo',
      //   })
      //   return
      // }

      setIsAuthenticatedApi(true)
      setIsAuthenticating(false)
    } catch (err) {
      console.error(err)
      logout()
      setIsAuthenticating(false)
    }
  }, [auth0User, getAccessTokenSilently, logout])

  useEffect(() => {
    if (isAuthenticatedAuth0) authenticateToApi()
  }, [isAuthenticatedAuth0, authenticateToApi])

  const login = (redirectUri?: string): Promise<void> => {
    let uri
    if (redirectUri) uri = `${window.location.origin}/${redirectUri}`

    return loginWithRedirect({ redirectUri: uri })
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isLoadingAuth: isLoadingAuth0 || isAuthenticatingApi,
        isAuthenticated: isAuthenticatedAuth0 && isAuthenticatatedApi,
      }}>
      {children}
    </AuthContext.Provider>
  )
}
