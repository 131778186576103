import { ROLES } from '@assets/constants'

export const getRoleName = (role?: ROLES): string | undefined => {
  const rolesMap: Record<ROLES, string> = {
    org_admin: 'Org admin',
    sub_admin: 'Sub admin',
  }

  return role ? rolesMap[role] : undefined
}
