import { COLORS, mainNavPages } from '@assets/constants'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import { AppText, SupportCard } from '@views/components'

interface Props {
  currentPageIdx: number
}

export const DetailsDrawerContent: React.FC<Props> = ({ currentPageIdx }) => {
  const navigation = useNavigation()
  return (
    <View style={styles.container}>
      <View>
        <View style={styles.title}>
          <AppText semiBold>Actions</AppText>
        </View>
        {mainNavPages.map((route, index) => {
          const isActive = index === currentPageIdx
          const activeColor = isActive ? COLORS.PRIMARY : COLORS.GRAY_700
          return (
            <TouchableOpacity
              key={route.page}
              onPress={() => navigation.navigate<any>(route.page)}
              style={[styles.navItem, isActive && styles.navItemActive]}>
              {route.getIcon(activeColor)}
              <AppText
                key={route.page}
                style={[styles.navItemTitle, isActive && styles.navItemTitleActive]}>
                {route.title}
              </AppText>
            </TouchableOpacity>
          )
        })}
      </View>
      <SupportCard />
    </View>
  )
}

const styles = StyleSheet.create({
  container: { marginTop: 30, height: '85%', justifyContent: 'space-between', width: 270 },
  title: {
    height: 50,
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  navItem: {
    flexDirection: 'row',
    height: 50,
    paddingVertical: 20,
    paddingHorizontal: 30,
    alignItems: 'center',
  },
  navItemTitle: {
    color: COLORS.GRAY_700,
    marginLeft: 15,
  },
  navItemTitleActive: {
    color: COLORS.PRIMARY,
  },
  navItemActive: {
    borderLeftWidth: 4,
    borderRadius: 2,
    borderColor: COLORS.PRIMARY,
  },
})
