enum EOrgInfo {
  basicInfo = 'basicInfo',
  adminInfo = 'adminInfo',
  beneficialOwners = 'beneficialOwners',
  sportInfo = 'sportInfo',
  beneficialOwnersReview = 'beneficialOwnersReview',
}

export const orgInfo = [
  { title: 'Basic info', route: EOrgInfo.basicInfo },
  { title: 'Admin info', route: EOrgInfo.adminInfo },
  { title: `Beneficial owners' info`, route: EOrgInfo.beneficialOwners },
  { title: 'Sport info', route: EOrgInfo.sportInfo },
  { title: 'Beneficial owners review', route: EOrgInfo.beneficialOwnersReview },
]
