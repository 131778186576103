import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { RootStackParamList } from '@views/navigation/types'
import { LandingPage, CreateAccount, LoginWithRedirect } from '@views/pages'
import { documentTitle } from '@views/utils'

const Stack = createStackNavigator<RootStackParamList>()

export default function AuthNavigation() {
  return (
    <Stack.Navigator initialRouteName="landingPage" screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="landingPage"
        component={LandingPage}
        options={{ title: documentTitle('Players Health', 'Home') }}
      />
      <Stack.Screen name="createAccount" component={CreateAccount} />
      <Stack.Screen name="login" component={LoginWithRedirect} />
    </Stack.Navigator>
  )
}
