export const TERMS = [
  {
    title: 'PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS SITE',
    content:
      'By using this site, you signify your assent to these Terms of Use (“TOU”). If you do not agree to all of these TOU do not use the Players Health Site (“Site”). Players Health may revise and update these TOU at any time. You are responsible for regularly reviewing these TOU and any Additional Terms posted on other sections of the Site. Your continued usage of the Site will mean you accept those changes. We take privacy seriously and take all reasonable precautions with both our software and websites. This Terms of Use also incorporates by reference the Players Health Privacy Policy.',
  },
  {
    title: 'THIS SITE DOES NOT PROVIDE MEDICAL ADVICE',
    content: `Welcome to Players Health! Players Health is an interactive mobile platform designed to enable coaches and parents to quickly and easily document a youth athlete’s injuries and/or medical conditions. Players Health is not a healthcare provider. Players Health does not provide medical advice, diagnosis, or treatment. The contents of the Players Health Site (“Content”) is for informational purposes only. None of the Content should be construed as medical recommendations, medical advice, or legal advice. The Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified healthcare professional with any questions you have regarding a medical condition or injury. Never disregard professional medical advice. If you believe you may have a medical emergency, call 911 immediately.

These TOU are an agreement between the users (“you” or “your”) of the websites identified below on the one hand, and Players Health and www.playershealth.com (collectively, “Company,” “we,” “our,” or “us”) on the other hand and govern your use of the Sites (as defined below) and the services and/or content, including, without limitation, any information, text, software, scripts, graphics, photographs, audio and/or visual materials (individually or in combination), features and other materials uploaded, downloaded or appearing on or available through the Sites (collectively, “Content”). The sites to which these TOU apply are those sites owned and operated by Company, including, without limitation www.playershealth.com (collectively, the “Sites”). Each of the Company and you shall be a “Party” or, collectively, “Parties.” The Sites are intended for a special audience over the age of 18 and involved in organized athletics and sports, including, athletes (“Players”) and sporting teams (“Teams”) as well as a certain coaches (“Coaches”) and potentially teams or their related entities’ administrators and other affiliated personnel (“Administrators”) (all collectively, “Participants”).
        
This site is limited to users over the age of 18. If you are under the age of 18, you may only use the Sites with the consent of your parent or legal guardian. By using this Site, YOU AFFIRMATIVELY REPRESENT AND WARRANT THAT YOU ARE AT LEAST 18 YEARS OF AGE (AND, IF UNDER 18 YEARS OLD {or the age of majority in the jurisdiction in which you reside], THAT YOU HAVE PARENTAL PERMISSION TO USE THE SITES) AND THAT YOU POSSESS THE LEGAL RIGHT TO USE THE SITES IN ACCORDANCE WITH THE STATED TERMS AND USAGE POLICIES. You hereby agree to supervise usage by, and be responsible for the actions of, any minors who use your computer and/or account(s) to ensure compliance herewith.
        `,
  },
  {
    title: 'PERSONAL NON-COMMERCIAL USE LIMITATION',
    content:
      'Unless otherwise specified, the Sites is for your personal and non-commercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any Content, information, software, products or services obtained from the Sites.',
  },
  {
    title: 'NO UNLAWFUL OR PROHIBITED USE',
    content:
      'As an express condition of your use of the System, you agree, represent and warrant that you will not use the System for any purpose that is unlawful or prohibited by these TOU or any terms, conditions, and notices contained herein. You may not use the System in any manner that could damage, disable, overburden, or impair the System or the network(s) connected to the System or interfere with any other party’s use and enjoyment of the System. You may not attempt to gain unauthorized access to the System, other accounts, computer systems or networks connected to the System, through hacking, password mining or any other means. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the System.',
  },
  {
    title: 'TERMINATION OF ACCESS',
    content: `Players Health reserves the right, in its sole discretion, to terminate or limit your access to any or all of the System or any portion thereof at any time, without notice.

MEMBER ACCOUNT, PASSWORD, AND SECURITY

Participants will receive a password from Players Health to access certain information on the System. You are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to notify Players Health immediately of any unauthorized use of your account or any other breach of security. Players Health will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by Players Health or another party due to someone else using your account or password. You may not use anyone else’s account at any time without the permission of the account holder and Players Health.
        `,
  },
  {
    title: 'DISCLAIMER OF WARRANTIES AND LIMITATION ON LIABILITY',
    content: `The use of the System and the Content is at your own risk.

When using the System, information will be transmitted over a medium that may be beyond the control and jurisdiction of Players Health. Accordingly, Players Health assumes no liability for or relating to the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Site.
            
The System and the Content are provided on an “as is” basis. PLAYERS HEALTH, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, PLAYERS HEALTH MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, CURRENTNESS, OR TIMELINESS OF THE CONTENT, SOFTWARE, TEXT, GRAPHICS, LINKS, OR COMMUNICATIONS PROVIDED ON OR THROUGH THE USE OF THE SYSTEM OR CONTENT.
            
IN NO EVENT SHALL PLAYERS HEALTH, OR ANY THIRD PARTIES MENTIONED ON THE SYSTEM BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, PERSONAL INJURY/WRONGFUL DEATH, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OF OR INABILITY TO USE THE SYSTEM OR THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT PLAYERS HEALTH, OR ANY THIRD PARTIES MENTIONED ON THE SITE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY EVENT, PLAYERS HEALTH, OR ANY THIRD PARTIES, LIABILITY WILL NOT EXCEED THE AMOUNT PAID BY YOU TO PLAYERS HEALTH TO ACCESS THE SYSTEM IN THE TWELVE (12) MONTHS PRIOR TO THE ACCRUAL OF A CLAIM BY YOU AGAINST PLAYERS HEALTH (OR, IF A TEAM OR PLAYER, NO GREATER THAN $100.000). IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SYSTEM, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SYSTEM. PLAYERS HEALTH, OR ANY THIRD PARTY, IS NOT LIABLE FOR ANY PERSONAL INJURY, INCLUDING DEATH, CAUSED BY YOUR USE OR MISUSE OF THE SYSTEM OR CONTENT. ANY CLAIMS ARISING IN CONNECTION WITH YOUR USE OF THE SYSTEM OR CONTENT MUST BE BROUGHT WITHIN ONE (1) YEAR OF THE DATE OF THE EVENT GIVING RISE TO SUCH ACTION OCCURRED. REMEDIES UNDER THESE TOU ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY PROVIDED FOR IN THE TOU.`,
  },
  {
    title: 'MATERIAL PROVIDED TO COMPANY',
    content: `If you submit any materials to the System or to Players Health, you are granting Players Health, its affiliated companies and its designees and sub licensees permission to use such information in connection with the operation of their businesses including, without limitation, the right to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat the same SOLELY on your closed Network; and to publish your name in connection with your Network. No compensation will be paid with respect to submitted materials. Players Health is under no obligation to post or use any materials you may provide and Players Health may remove any materials at any time in its reasonable discretion.
    
UNDER NO CIRCUMSTANCES SHALL PLAYERS HEALTH OR ITS AFFILIATES BE LIABLE IN ANY WAY FOR ANY MATERIALS SUBMITTED TO BY USERS.
        `,
  },
  {
    title: 'INDEMNIFICATION',
    content:
      'You agree to indemnify and hold Players Health, its affiliates, and their respective officers, directors, employees, agents, successors and assigns harmless from any claim, demand, or damage, including reasonable attorneys’ fees, asserted by any third party due to or arising out of your use of the System and/or any information (text or graphical), software, or products obtained through the System and/or from Players Health. We reserve the right, at our own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with us in asserting any available defenses.',
  },
  {
    title: 'APPLICABLE LAW/DISPUTE RESOLUTION',
    content:
      'You expressly agree that all disputes against Players Health, including but not limited to disputes as to these TOU; the System’s licenses; the System’s access restrictions and/or termination; Players Health’s trademarks, copyrights, patents, trade secrets, trade dress and other intellectual property rights and the intellectual property rights of you and/or third parties; member accounts; privacy; and disclaimer of warranties shall be governed by the internal laws of the State of Delaware, U.S.A. without regard to choice of law principles and you and Players Health both expressly agree that any and all disputes that may arise out of these TOU or out of each Party’s relationship with the other shall be resolved in a court of competent jurisdiction located within Hennepin County, Minnesota. You hereby irrevocably consent to exclusive jurisdiction (and to be personally subject to said jurisdiction) and venue in such courts.',
  },
  {
    title: 'WAIVERS AND SEVERABILITY',
    content: `No waiver of any of the terms of these TOU will be valid unless in writing and designated as such. No failure or delay in enforcing a party’s rights shall be construed as a waiver.

If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.`,
  },
  {
    title: 'COPYRIGHTS AND TRADEMARKS',
    content:
      'All of the materials and the Content appearing on the System are either owned by Players Health or licensed or sub-licensed (including, without limitation, through these TOU) to Players Health and are used by Players Health in accordance with the applicable licenses and with applicable law. You may not copy, distribute or otherwise disseminate any materials or Content (including any logos, images, or data), from the System unless you are the owner of said materials or content or have express written permission from the owner of said materials or content to do so.',
  },
  {
    title: 'LANGUAGE',
    content:
      'It is the express will of the parties that this agreement and all related documents have been drawn up in English. In the event these TOU are translated into another language or otherwise reprinted, this (and future amended) English language version of the TOU shall govern as to any inconsistencies.',
  },
  {
    title: 'ENTIRE AGREEMENT',
    content:
      'Unless otherwise specified herein or in a writing executed by both parties, these TOU constitute the entire agreement between you (the user) and Players Health with respect to the use of the System and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and Players Health with respect to the System.',
  },
  {
    title: 'ERRORS, OMISSIONS, AND CHANGES',
    content:
      'While Players Health makes every effort to ensure the accuracy of materials presented, you, the user is warned that this service could include typographical errors and/or technical inaccuracies. Players Health reserves the right to make improvements and/or changes to these TOU and/or to the products and services described on the System at any time.',
  },
  {
    title: 'CUSTOMER SERVICE',
    content:
      'If you have any questions, comments, or concerns, please contact our customer service department either by email at support@playershealth.com or by submitting a support ticket.',
  },
]
