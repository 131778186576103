import React from 'react'
import { View, StyleSheet } from 'react-native'
import { AppText, SupportCard } from '@views/components'
import { Ionicons } from '@expo/vector-icons'
import { COLORS } from '@assets/constants'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useAuth } from '@views/hooks'

const OtherPage: React.FC = () => {
  return (
    <View style={styles.container}>
      <View>
        <AppText semiBold style={styles.title}>
          Additional
        </AppText>
        <LogoutButton />
      </View>
      <SupportCard />
    </View>
  )
}

const LogoutButton = () => {
  const { logout } = useAuth()

  return (
    <TouchableOpacity onPress={logout} style={styles.logoutContainer}>
      <Ionicons name="exit" size={24} color={COLORS.GRAY_700} />
      <AppText style={styles.logoutButtonText}>Log out </AppText>
    </TouchableOpacity>
  )
}

export default OtherPage

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.LIGHT,
    paddingTop: 32,
    padding: 16,
    flex: 1,
    justifyContent: 'space-between',
  },
  logoutButtonText: {
    marginLeft: 15,
    color: COLORS.GRAY_700,
  },
  title: { marginBottom: 20 },
  logoutContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
