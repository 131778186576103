import React from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  AppText,
  Form,
  FormInput,
  Button,
  Card,
  Row,
  openModal,
  closeModal,
  InputModal,
} from '@views/components'
import { fundAccountFields, FundAccountSchema, achAmountField, AchSchema } from '@views/schemas'
import { useSystemConfig } from '@views/hooks'

import { COLORS } from '@assets/constants'
import cardsExample from '@assets/images/cards_example.png'
import depositExample from '@assets/images/deposit_example.png'
import transferExample from '@assets/images/transfer_example.png'
import autoPayExample from '@assets/images/autopay_example.png'

const FundAccount = () => {
  const { isSmallDevice } = useSystemConfig()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FundAccountSchema),
  })

  const sendACH = (amount: string) => {
    // eslint-disable-next-line no-console
    console.log(amount)
    // @TODO submit amount and redirect to dashboard once bank account integration/create account auth work is done
    closeModal()
    openModal({
      dismiss: false,
      type: 'success',
      title: 'Sports Organization Activated',
      subtitle: 'Your deposit was successful.',
      buttons: [
        <Button
          style={!isSmallDevice ? styles.modalButton : styles.modalButtonSmall}
          type="primary"
          text="View Account"
          onPress={closeModal}
        />,
      ],
    })
  }

  const onSubmit = (data: any) => {
    // @TODO: open plaid flow
    if (data.depositType === 'ach')
      openModal({
        title: 'Deposit by ACH',
        content: (
          <InputModal
            field={achAmountField}
            schema={AchSchema}
            subLabel="Minimum $50"
            onSubmit={sendACH}
          />
        ),
      })

    // eslint-disable-next-line no-console
    console.log(data)
  }

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between' }}>
      <AppText style={styles.title} bold>
        Account Creation
      </AppText>
      <AppText>Make a remote deposit ($50 minimum) to set up your account.</AppText>
      <AppText style={styles.subTitle} bold>
        Fund your account
      </AppText>
      <Form showButtons={false}>
        <View style={!isSmallDevice && styles.inputWrapper}>
          <FormInput
            name={fundAccountFields.sportsOrgName.name}
            label={fundAccountFields.sportsOrgName.label}
            type={fundAccountFields.sportsOrgName.type}
            control={control}
            error={errors[fundAccountFields.sportsOrgName.name] as unknown as FieldError}
            placeholder={fundAccountFields.sportsOrgName.placeholder}
          />
          <View style={styles.radioButtons}>
            <FormInput
              name={fundAccountFields.depositType.name}
              label={fundAccountFields.depositType.label}
              type={fundAccountFields.depositType.type}
              control={control}
              error={errors[fundAccountFields.depositType.name] as unknown as FieldError}
              items={fundAccountFields.depositType.items}
            />
          </View>
        </View>
        <AppText style={styles.featuresTitle} bold>
          Sports organization features
        </AppText>
        <ScrollView horizontal contentContainerStyle={styles.cardRow}>
          <Card style={styles.card} color={COLORS.CYAN_200} imgUrl={cardsExample} title="Cards">
            <AppText>Create virtual/physical cards</AppText>
          </Card>
          <Card
            style={styles.card}
            color={COLORS.BLUE_300}
            imgUrl={depositExample}
            title="Make deposits">
            <AppText>Make deposits by check</AppText>
          </Card>
          <Card
            style={styles.card}
            color={COLORS.AZURE_LIGHT}
            imgUrl={transferExample}
            title="Sending funds">
            <AppText>Transfer or wire funds</AppText>
          </Card>
          <Card
            style={styles.card}
            color={COLORS.PURPLE_LIGHT}
            imgUrl={autoPayExample}
            title="Auto pay">
            <AppText>Create regular payments</AppText>
          </Card>
        </ScrollView>
      </Form>
      <Row style={styles.buttonWrapper}>
        <Button
          style={!isSmallDevice ? styles.button : {}}
          onPress={handleSubmit(onSubmit)}
          type="primary"
          text="Make a Deposit"
        />
      </Row>
    </ScrollView>
  )
}

export default FundAccount

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: COLORS.WHITE,
  },
  title: {
    fontSize: 24,
    paddingTop: 16,
    paddingBottom: 6,
  },
  subTitle: {
    fontSize: 18,
    marginTop: 32.5,
  },
  inputWrapper: {
    maxWidth: '50%',
  },
  radioButtons: {
    marginTop: 30,
  },
  featuresTitle: {
    fontSize: 16,
    marginBottom: 16,
  },
  cardRow: {
    flexGrow: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  card: {
    width: '24%',
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    marginBottom: 30,
  },
  button: {
    maxWidth: 270,
  },
  modalButton: {
    width: 400,
  },
  modalButtonSmall: {
    maxWidth: 200,
  },
})
