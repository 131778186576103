import {
  alphaNumericRegex,
  dateRegex,
  emailRegex,
  noSelectionRegex,
  phoneRegex,
  ssnRegex,
  states,
  stringRegex,
  zipRegex,
  percentageRegex,
} from '@assets/constants'
import { IInputProps } from '@views/components/Form'
import { InputTypes } from '@views/components/Input'

export const beneficialOwnerFields = (formNum: number): IBeneficialOwnersInfoFields => {
  return {
    firstName: {
      name: `firstNameBO${formNum}`,
      label: 'First name',
      type: InputTypes.text,
      placeholder: 'Enter first name',
      rules: {
        required: true,
        pattern: stringRegex,
      },
    },
    lastName: {
      name: `lastNameBO${formNum}`,
      label: 'Last name',
      type: InputTypes.text,
      placeholder: 'Enter last name',
      rules: {
        required: true,
        pattern: stringRegex,
      },
    },
    phone: {
      name: `phoneBO${formNum}`,
      label: 'Phone',
      type: InputTypes.text,
      placeholder: 'Enter phone',
      rules: {
        required: true,
        pattern: phoneRegex,
      },
    },
    email: {
      name: `emailBO${formNum}`,
      label: 'Email',
      type: InputTypes.text,
      placeholder: 'Enter email',
      rules: {
        required: true,
        pattern: emailRegex,
      },
    },
    percentageOwnership: {
      name: `percentageOwnershipBO${formNum}`,
      label: 'Percentage of ownership',
      type: InputTypes.text,
      placeholder: 'Must be at least 25%',
      rules: {
        required: true,
        pattern: percentageRegex,
      },
    },
    ssn: {
      name: `ssnBO${formNum}`,
      label: 'SSN',
      type: InputTypes.password,
      placeholder: 'Enter SSN',
      rules: {
        required: true,
        pattern: ssnRegex,
      },
    },
    dob: {
      name: `dobBO${formNum}`,
      label: 'Date of birth',
      type: InputTypes.text,
      placeholder: 'MM/DD/YYYY',
      rules: {
        required: true,
        pattern: dateRegex,
      },
    },
    addrStreet1: {
      name: `addrStreet1BO${formNum}`,
      label: 'Street address',
      type: InputTypes.text,
      placeholder: 'Enter street address',
      rules: {
        required: true,
        pattern: alphaNumericRegex,
      },
    },
    addrStreet2: {
      name: `addrStreet2BO${formNum}`,
      label: 'Apartment, suite, etc (optional)',
      type: InputTypes.text,
      placeholder: 'Optional',
      rules: {
        required: false,
        pattern: alphaNumericRegex,
      },
    },
    addrCity: {
      name: `addrCityBO${formNum}`,
      label: 'City',
      type: InputTypes.text,
      placeholder: 'Enter city',
      rules: {
        required: true,
        pattern: stringRegex,
      },
    },
    addrState: {
      name: `addrStateBO${formNum}`,
      label: 'State',
      type: InputTypes.picker,
      placeholder: 'Select state',
      items: states,
      rules: {
        required: true,
        pattern: noSelectionRegex,
      },
    },
    addrZip: {
      name: `addrZipBO${formNum}`,
      label: 'ZIP code',
      type: InputTypes.text,
      placeholder: 'Enter ZIP',
      rules: {
        required: true,
        pattern: zipRegex,
      },
    },
  }
}

export interface IBeneficialOwnersInfoFields {
  firstName: IInputProps
  lastName: IInputProps
  phone: IInputProps
  email: IInputProps
  percentageOwnership: IInputProps
  ssn: IInputProps
  dob: IInputProps
  addrStreet1: IInputProps
  addrStreet2: IInputProps
  addrCity: IInputProps
  addrState: IInputProps
  addrZip: IInputProps
}

export interface IBeneficialOwnersInfo {
  id?: string
  firstName: string
  lastName: string
  phone: string
  email: string
  percentageOwnership: string
  ssn: string
  dob: string
  addrStreet1: string
  addrStreet2: string
  addrCity: string
  addrState: string
  addrZip: string
}
