import { AuthenticateUserData, UserRepository } from '@domain/repositories/UserRepository'

export const userService = (repository: UserRepository): UserRepository => ({
  createAdminUser: (user) => {
    return repository.createAdminUser(user)
  },
  authenticateUser: (data: AuthenticateUserData) => {
    return repository.authenticateUser(data)
  },
  createAdminWithDisclosures: (user) => {
    return repository.createAdminWithDisclosures(user)
  },
  createBeneficialOwners: (owners, businessId) => {
    return repository.createBeneficialOwners(owners, businessId)
  },
})
