import React, { useContext, useEffect } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { AppText, RenderRow, Form, ButtonRow } from '@views/components'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import { basicInfoFields, BasicInfoSchema } from '@views/schemas'
import { CreateAccountContext } from '@views/contexts'
import moment from 'moment'

const BasicInfo = ({ navigation }: CreateAccountStackScreenProps<'basicInfo'>) => {
  const { state, postBasicInfo } = useContext(CreateAccountContext)
  const {
    loading,
    error,
    data: { basicInfo },
  } = state

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(BasicInfoSchema) })
  const onSubmit = async (data: any) => {
    await postBasicInfo(data)
    if (error === '') navigation.navigate('adminInfo')
  }
  useEffect(() => {
    reset({
      ...basicInfo,
      orgFormationDate:
        basicInfo?.orgFormationDate !== ''
          ? moment(basicInfo?.orgFormationDate).format('MM/DD/YYYY')
          : '',
    })
  }, [basicInfo, reset])

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <AppText style={styles.title} bold>
          Account Creation
        </AppText>
        <AppText style={styles.subTitle1}>
          Create an account to start using Players Health Services.
        </AppText>
        <AppText bold style={styles.subTitle2}>
          Organization info /{' '}
          <AppText bold style={{ color: '#AEB4BB' }}>
            Basic info
          </AppText>
        </AppText>
        <Form showDisclaimer>
          <RenderRow
            fields={[basicInfoFields.orgLegalName, basicInfoFields.orgType]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[
              basicInfoFields.orgEIN,
              basicInfoFields.orgFormationDate,
              basicInfoFields.orgFormationState,
            ]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[
              basicInfoFields.orgPhone,
              basicInfoFields.orgEmail,
              basicInfoFields.orgLegalStructure,
            ]}
            errors={errors}
            control={control}
          />
          <View style={styles.horizontalRule} />
          <AppText bold style={styles.subTitle2}>
            Organization legal address
          </AppText>
          <RenderRow
            fields={[basicInfoFields.orgAddrStreet1, basicInfoFields.orgAddrStreet2]}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[
              basicInfoFields.orgAddrCity,
              basicInfoFields.orgAddrState,
              basicInfoFields.orgAddrZip,
            ]}
            errors={errors}
            control={control}
          />
        </Form>
      </View>
      <ButtonRow
        buttonLabel="Next"
        onSubmit={handleSubmit(onSubmit)}
        onBack={() => navigation.navigate('landingPage')}
      />
    </ScrollView>
  )
}

export default BasicInfo

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: '#FFF',
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    paddingTop: 32,
    paddingBottom: 6,
  },
  subTitle1: {
    fontSize: 16,
    maxWidth: 850,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
  link: {
    textDecorationLine: 'underline',
  },
  formWrapper: {
    borderBottomColor: '#EEEEF0',
    borderBottomWidth: 1,
    marginBottom: 25,
  },
  horizontalRule: {
    width: '100%',
    height: 1,
    backgroundColor: '#EEEEF0',
  },
})
