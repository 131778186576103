import React from 'react'
import { createDrawerNavigator, DrawerContentComponentProps } from '@react-navigation/drawer'
import { BusinessPageWeb, TransactionsPage, SettingsPage, PersonalDocuments } from '@views/pages'

import { documentTitle } from '@views/utils'
import { LeftPanel, DetailsDrawerContent } from '@views/components'
import {
  BUSINESS_ACCOUNT_ROUTE,
  PERSONAL_DOCUMENTS_ROUTE,
  SETTINGS_ROUTE,
  TRANSACTIONS_ROUTE,
} from '@assets/constants'

const Drawer = createDrawerNavigator()

const DrawerContent = ({ navigation }: DrawerContentComponentProps) => {
  const state = navigation.getState()
  return <LeftPanel content={<DetailsDrawerContent currentPageIdx={state.index} />} />
}

export const WebNavigator = () => {
  return (
    <Drawer.Navigator
      initialRouteName={BUSINESS_ACCOUNT_ROUTE}
      useLegacyImplementation
      screenOptions={{
        drawerStyle: { width: 270 },
        headerShown: false,
        drawerType: 'permanent',
      }}
      drawerContent={DrawerContent}>
      <Drawer.Screen
        name={BUSINESS_ACCOUNT_ROUTE}
        component={BusinessPageWeb}
        options={{ title: documentTitle('Account', 'Business Account') }}
      />
      <Drawer.Screen
        name={TRANSACTIONS_ROUTE}
        component={TransactionsPage}
        options={{ title: documentTitle('Account', 'Transactions') }}
      />
      <Drawer.Screen
        name={SETTINGS_ROUTE}
        component={SettingsPage}
        options={{ title: documentTitle('Account', 'Settings') }}
      />
      <Drawer.Screen
        name={PERSONAL_DOCUMENTS_ROUTE}
        component={PersonalDocuments}
        options={{ title: documentTitle('Account', 'Personal Documents') }}
      />
    </Drawer.Navigator>
  )
}
