import { HttpRepository, HttpResponse } from '@domain/repositories/HttpRepository'
import { BusinessRepository } from '@domain/repositories/BusinessRepository'
import {
  BasicInfoToBusinessDTO,
  BusinessDTO,
  BusinessDTOtoBasicInfo,
} from '@microservices/dto/BusinessDTO'
import { IBasicInfo } from '@views/schemas'

export const businessRepository = (client: HttpRepository): BusinessRepository => ({
  getBusinesses: async (): Promise<IBasicInfo[]> => {
    const response = await client.get<BusinessDTO[]>('business')
    if (response) {
      const { data: businesses } = response
      return businesses?.map(
        (businessDTO: BusinessDTO): IBasicInfo => BusinessDTOtoBasicInfo(businessDTO)
      )
    }
    return []
  },

  getBusinessById: async (id) => {
    const response = await client.get<BusinessDTO>(`business/${id}`)
    if (response) {
      const business: BusinessDTO = response.data
      if (business) return BusinessDTOtoBasicInfo(business)
    }
    return
  },

  createBusiness: async (basicInfo: IBasicInfo) => {
    const business = BasicInfoToBusinessDTO(basicInfo)
    return await client.post('business', business)
  },
})
