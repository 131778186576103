import React from 'react'
import { Image, ScrollView, StyleSheet, View } from 'react-native'

import { Button, AppText, QRCodeGenerator } from '@views/components'
import { useSystemConfig } from '@views/hooks'

import info_attention from '@assets/images/info_attention.png'
import { COLORS } from '@assets/constants'

export const KYCModal = ({ navigation }: any) => {
  const { isSmallDevice } = useSystemConfig()
  return (
    <View style={isSmallDevice ? styles.containerSmall : styles.container}>
      <ScrollView>
        <Image source={info_attention} style={styles.modalLogo} />
        <AppText bold style={styles.title}>
          Personal Information Required
        </AppText>
        <AppText style={styles.sectionContent}>
          In order to verify your organization, please upload additional documents.
        </AppText>
        <AppText style={styles.sectionContent}>
          Scan the QR code below with your mobile device to upload photographs of your documents.
        </AppText>
        <View style={styles.qrContainer}>
          <QRCodeGenerator
            qrURL={`${window.location.origin}/login?redirect_uri=personalDocuments`}
          />
        </View>
        <AppText style={styles.sectionContent}>
          Once you’ve submitted the photographs on your mobile device, click the ‘Refresh’ button to
          continue.
        </AppText>
      </ScrollView>
      {/* @TODO update this route. This is needed to test the qr route */}
      <Button
        style={styles.button}
        onPress={() => {
          navigation.navigate('login', { redirect_uri: 'personalDocuments' })
        }}
        type="primary"
        text="Refresh"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: { maxHeight: '75vh', marginBottom: 10 },
  containerSmall: { maxHeight: '60vh', marginBottom: 10 },
  title: { fontSize: 24, marginBottom: 10, textAlign: 'center' },
  sectionContent: { lineHeight: 24, marginBottom: 10, textAlign: 'center' },
  button: { marginTop: 10 },
  modalLogo: { height: 86, width: 112, marginBottom: 24, display: 'flex', alignSelf: 'center' },
  qrContainer: {
    padding: 15,
    borderStyle: 'solid',
    borderRadius: 8,
    borderColor: COLORS.DARK,
    borderWidth: 1,
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 10,
  },
})
