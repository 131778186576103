import * as yup from 'yup'
import { IInputProps } from '@views/components/Form'
import { InputTypes } from '@views/components/Input'

export const IBeneficialOwnersFields: IBeneficialOwnerEmailsFields = {
  email1: {
    name: 'emailBO1',
    label: 'Email address',
    type: InputTypes.text,
    placeholder: 'Enter email address',
  },
  email2: {
    name: 'emailBO2',
    label: 'Email address',
    type: InputTypes.text,
    placeholder: 'Enter email address',
  },
  email3: {
    name: 'emailBO3',
    label: 'Email address',
    type: InputTypes.text,
    placeholder: 'Enter email address',
  },
  email4: {
    name: 'emailBO4',
    label: 'Email address',
    type: InputTypes.text,
    placeholder: 'Enter email address',
  },
  atLeastOne: {
    name: 'atLeastOne',
    type: InputTypes.hidden,
  },
}

export interface IBeneficialOwnerEmailsFields {
  email1: IInputProps
  email2: IInputProps
  email3: IInputProps
  email4: IInputProps
  atLeastOne: IInputProps
}

export interface IBeneficialOwnerEmails {
  email1: string
  email2: string
  email3: string
  email4: string
}

export const BeneficialOwnerEmailsSchema = yup.object().shape({
  emailBO1: yup.string().email('Invalid email'),
  emailBO2: yup.string().email('Invalid email'),
  emailBO3: yup.string().email('Invalid email'),
  emailBO4: yup.string().email('Invalid email'),
  atLeastOne: yup.string().when(['emailBO1', 'emailBO2', 'emailBO3', 'emailBO4'], {
    is: (emailBO1: string, emailBO2: string, emailBO3: string, emailBO4: string) => {
      return Boolean(emailBO1 || emailBO2 || emailBO3 || emailBO4)
    },
    then: yup.string(),
    otherwise: yup.string().required('At least one email required'),
  }),
})
