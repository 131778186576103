import React, { useState } from 'react'
import { View, StyleSheet, NativeScrollEvent } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import { AppText, Spacing, Button } from '@views/components'

import { useSystemConfig } from '@views/hooks'

interface Props {
  onAccepted: () => void
}

const MARGIN_BOTTOM = 10
export const Policy: React.FC<Props> = ({ onAccepted }) => {
  const { isSmallDevice } = useSystemConfig()
  const [policyRead, setPolicyRead] = useState(false)

  const isBottomReached = ({
    contentOffset,
    contentSize,
    layoutMeasurement,
  }: NativeScrollEvent) => {
    const offsetY = contentOffset.y
    const layoutHeight = layoutMeasurement.height
    const contentHeight = contentSize.height

    const totalScroll = contentHeight - layoutHeight - MARGIN_BOTTOM
    return offsetY >= totalScroll
  }

  return (
    <View style={isSmallDevice ? styles.containerSmall : styles.container}>
      <AppText bold style={styles.title}>
        Players Health Privacy Policy
      </AppText>
      <ScrollView
        scrollEventThrottle={16}
        onScroll={({ nativeEvent }) => isBottomReached(nativeEvent) && setPolicyRead(true)}>
        <AppText style={styles.text}>
          Players Health's mission is to help youth athletes set and reach their goals by making
          sports a better, safer, and more secure place. We achieve our mission by offering sports
          organizations the best risk management solutions, comprehensive insurance coverage, policy
          options, faster claims experiences, and a variety of products and services to address
          their specific needs. To do this, we recognize you must trust us with your personal
          information. Maintaining your trust is one of Players Health's top priorities, and as a
          result, we abide by the following principles to protect your privacy:
        </AppText>

        <Spacing />
        <Spacing />

        <AppText bold style={[styles.tab, styles.text]}>
          • We protect your personal information and use it only in connection with your use of our
          website and Service as set forth in our Terms of Use Agreement and described in this
          Privacy Policy.
        </AppText>

        <Spacing />

        <AppText bold style={[styles.tab, styles.text]}>
          • Because privacy and security go hand-in-hand, we have implemented industry standard
          administrative, technical and physical security safeguards designed to protect your and
          your employees' information.
        </AppText>

        <Spacing />
        <Spacing />

        <AppText style={styles.text}>
          This Privacy Policy applies to all the data, including previously collected data, from
          visitors of playershealth.com as well as to users (“Users”) of the online services, mobile
          services, software, and other services that we provide or otherwise make available on
          playershealth.com (collectively, “the Service”).
        </AppText>

        <Spacing />

        {/* Bold and hyperlinks*/}
        <AppText bold style={styles.text}>
          By using the Service, you agree to the terms of this Privacy Policy and ourTerms of use.
          Revisions to the Privacy Policy.
        </AppText>

        <Spacing />
        <Spacing />

        <AppText bold>INFORMATION COLLECTION</AppText>

        <Spacing />

        <AppText style={styles.text}>
          The amount of information we collect depends on which features of the Service you use.
          Players Health needs personal information about your organization, your athletes, coaches
          and any stakeholder as applicable to provide the Service, and your providing the
          information to us constitutes your consent to our processing of this information.
          Additionally, because we provide a wide range of Risk Management and benefits management
          features, we may collect information about you from your sports organization, rather than
          directly from you. By submitting information to us, you represent and warrant that you
          have permission and the authority to do so and that any permission has been granted in
          accordance with applicable law. More specifically, we may collect the following categories
          of personal information about you:
        </AppText>

        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Registration Information: </AppText>Your email address and password (your
          login credentials), and other information necessary to set up your account on the Service,
          which will depend on the features you use or your employer chooses to use.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Identity information: </AppText>Your name, physical address, Social
          Security Number/tax identification number, and other identifiers used to manage and track
          staff compliance processes and benefits.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Demographic information: </AppText>If you voluntarily submit information
          regarding your race in response to Equal Employment Opportunity Commission questionnaires
          to your employer through Players Health.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Bank account information: </AppText>Players Health needs bank account and
          routing numbers if you use the Service to provide insurance services, brokerage partner
          payments, telemedicine and clinic engagement and intake.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Insurance information: </AppText>If your sports organization manages or
          obtain insurances (General Liability, Participant Accident, D&O, Crime and Abuse &
          Molestation) through Players Health Cover, we may assist you with enrollment in insurance
          coverage, and Players Health Cover may process information related to your insurance
          coverage, including eligibility and enrollment information. We may also help you process
          or resolve claims for injuries, or incidents involving abuse.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Background Check Information: </AppText>If you use PH Clear to manage your
          background check process, we may work with a third party to facilitate staff background
          checks and collect this information for you.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Information from Third-Party Sites and Services: </AppText>If you use
          Players Health to manage third party services that integrate with the Service, we may have
          access to your information on such third-party services. We may, for example, have
          information about your use of a third-party registration service provider.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Device and IP Information: </AppText>When you access the Service, we
          automatically receive the internet protocol (“IP”) address of your computer or the proxy
          server that you use to access the internet, in addition to other technical information
          such as your computer operating system details, your type of web browser, your mobile
          device (including your mobile device identifier provided by your mobile device operating
          system), your mobile operating system, and the name of your ISP or mobile carrier. Players
          Health may log this information and analyze it to determine anomalous behavior, detect
          threats and generally improve the Service.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• From your interaction with Customer Support: </AppText>When you contact
          our customer support team, we may review your Players Health user profile. Customer
          support will collect the information necessary to categorize your questions, respond to
          them, and, if applicable, investigate and resolve any issue you bring to our attention or
          that we discover independently. We may also use this information to track potential
          problems and trends, improve the Service, and customize our support responses to better
          serve you.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Cookies, Beacons, Mobile SDKs, and similar technologies: </AppText>We use
          cookies and similar technologies, including beacons in email messages, and application
          developer platforms that use mobile Software Development Kits (“SDKs”) to collect
          information about your use of any mobile application that we make available to you, and
          other information related to how browsers and mobile devices interact with the Service.
          These technologies help us recognize your browser or mobile device when you log in to our
          website and use our mobile applications and help us improve your experience with the
          Service, increase Players Health’s security, and measure and improve the use and
          effectiveness of our Service. Players Health also uses these technologies to serve
          advertising to you on other sites using third party advertising services. You can control
          the cookies set in your browser and even block them entirely by configuring your browser
          settings. If you block cookies, however, you may not be able to use the Services.
          Additional options for controlling how or whether Players Health can target you with
          advertising is detailed in Section 2 (advertising and marketing) of this policy. By
          visiting Players Health website or using our mobile applications, you consent to the
          placement of cookies and beacons on your browser, and our use of mobile SDKs in accordance
          with this Privacy Policy.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Health care information: </AppText>Players Health will use information
          related to your health care in order to provide the Service.
        </AppText>

        <Spacing />
        <Spacing />

        <AppText bold style={styles.text}>
          USES AND DISCLOSURES OF YOUR INFORMATION
        </AppText>

        <Spacing />

        <AppText style={styles.text}>
          Players Health uses the information it collects about you as set forth below:
        </AppText>

        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• To provide the Service: </AppText>Players Health uses the information it
          collects to provide the Service to our Users - including to provide User-specific and
          customized experiences where necessary and appropriate, with the goal of enabling a safe
          sports environment through our platform.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• To improve and customize the Service: </AppText>Players Health seeks to
          improve your experience with the Service. As a result, we will analyze data we collect
          about your use of the Service to inform how we can improve our products, including our
          software, infrastructure, user interfaces, and integrations with our partners.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• To integrate with Third Parties: </AppText>To support our Users and ensure
          safety in sports, Players Health may integrate our system with third parties. For example,
          Players Health may integrate the Service to support your preferred sports registration
          service. If you have an independent direct relationship with a third party integrated into
          the Service, any permissions you may have granted to that third party will govern their
          use of your data. Otherwise, any third party used by Players Health to help provide the
          Services will not have any independent right to access or use your personal information.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• To communicate with you: </AppText>Players Health wants to help you know
          how to best take advantage of the Service, especially as we improve and develop new
          features. We may send messages relating to your use of the Service - for example, we may
          send you a note that a new concussion legislation has passed. We may also send you
          messages about Service-related matters through our dashboard or by email. Also, because we
          want to improve the Service, we may occasionally send promotional materials out via email
          so that you are able to take advantage of new or unused features. More details about our
          advertising and marketing activities are provided below. You can control whether you
          receive future non-Service-related messages by unsubscribing in the message itself.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• To advertise and market to you: </AppText>As noted above, Players Health
          relies on cookies, beacons, and mobile SDKs to recognize your browser or mobile device in
          order to serve you ads. These same technologies are used by Players Health to analyze the
          effectiveness of our advertising and email marketing campaigns. Players Health may use
          third party services, data, and software tools to target advertising to you, perform
          campaign effectiveness analysis, help us identify and reach potential Users, and track the
          performance of our site and advertising generally. Players Health's third-party
          advertising vendors do not have any independent right to use Players Health's personal
          information or that of its Users.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• To work with our vendors and service providers: </AppText>Players Health
          uses third party vendors and service providers to provide the Service to you. For example,
          we use Amazon Web Services (AWS) to host the Service in the secure AWS cloud, and, as
          mentioned above, we also use third parties to help us advertise and market to you. We may
          also provide some of your information (your email address, for example) to a service
          provider in order to reach you with an email campaign about new Players Health features,
          however, our service providers and vendors have no independent right to use your data.
          Similarly, Players Health’s instance in AWS is not accessible to any Amazon employee
          except at our request and to carry out our instructions. In other words, all our vendors
          are limited to their use of your data in the same way that we are limited in our use of
          your data by this Privacy Policy.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• To comply with valid legal process and similar disclosures: </AppText>
          Players Health may disclose your information as permitted by law or as reasonably
          necessary to:
        </AppText>

        <AppText style={[styles.largeTab, styles.text]}>
          1. Comply with a legal requirement or process, for example, civil and criminal subpoenas,
          court orders or other compulsory disclosures;
        </AppText>
        <AppText style={[styles.largeTab, styles.text]}>
          2. Enforce this Privacy Policy or our Terms of Use Agreement; or,
        </AppText>
        <AppText style={[styles.largeTab, styles.text]}>
          3. To protect the rights, property, or safety of Players Health, our employees, or our
          Customers and Users.
        </AppText>

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>
            • To disclose to a third party as the result of a sale or change in control of Players
            Health:{' '}
          </AppText>
          Players Health may disclose your personal information to a third party as part of a sale
          of the assets of Players Health, or an affiliate, or as a result of a change in control of
          Players Health or an affiliate, or in preparation for any of these events. Any third party
          to which we transfer control or sell our assets will have the right to continue to use the
          personal and other information that you provide to us in the manner set out in this
          Privacy Policy.
        </AppText>

        <Spacing />
        <Spacing />

        <AppText bold>YOUR CHOICES</AppText>

        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Opt-Out of Behavioral Advertising and Cookies. </AppText>To learn more
          about advertising technologies and how to opt-out of online behavioral advertising, please
          visit https://www.allaboutcookies.org or the Network Advertising Initiative's online
          resources, at https://www.networkadvertising.org, or the Digital Advertising Alliance at
          https://www.aboutads.info/choices/. You may also opt out of tracking and receiving online
          behavioral advertising on your mobile device by some mobile advertising companies and
          other similar entities by downloading the App Choices app at
          https://wwwaboutads.com/info/appchoices. These sites are not controlled by Players Health,
          but provide an opt-out from advertising exchanges. Depending on your mobile device, you
          also can control tracking technologies through your settings by resetting your advertising
          ID. Please note that the only way to completely “opt out” of the tracking that cookies and
          other technologies enable is to actively manage the settings on your browser or mobile
          device, which may negatively impact your ability to use the Service.
        </AppText>

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Retention: </AppText>Players Health retains the personal information you
          provide while your account is in existence or as needed to provide you the Service,
          including customer support-related reporting and trend analysis. We may retain Customer or
          User information if retention is reasonably necessary to comply with our legal
          obligations, meet regulatory requirements, resolve disputes between Customer, Users, or
          related parties, prevent fraud and abuse, or enforce this Privacy Policy or our Terms of
          Use Agreement. We may retain personal information, for a limited period of time, if
          requested by law enforcement.
        </AppText>

        <Spacing />
        <Spacing />

        <AppText bold>IMPORTANT INFORMATION</AppText>

        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• HIPAA: </AppText>Notwithstanding anything to the contrary, if Players
          Health is providing you with the Service pursuant to a contract with a Covered Entity,
          Players Health will be a Business Associate of that Covered Entity and shall treat all
          Protected Health Information as required under the terms of its Business Associate
          Agreement (capitalized terms not previously defined shall have meaning set forth at 45 CFR
          160.103). This Privacy Policy shall not limit the rights or obligations of Players Health
          under the aforementioned Business Associate Agreement.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Security: </AppText>Players Health has implemented industry standard
          administrative, technical, and physical security safeguards designed to protect the
          personal information that you provide. Access to your data on our Service is
          password-protected, and data is protected by SSL encryption when it is exchanged between
          your web browser and the Service. We also support secure https access to the
          Playershealth.com website. To protect any data you store on our servers, we also regularly
          monitor our system for possible vulnerabilities and attacks.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          • As you know, however, the Internet is not a 100% secure environment, and as a result, we
          cannot ensure or warrant the security of any information that you transmit to us. There is
          no guarantee that information may not be accessed, disclosed, altered, or destroyed by
          breach of any of our administrative, technical, or physical safeguards. It is your
          responsibility to protect the security of your login information. Please help keep your
          account safe by using a strong password.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Storage and Processing: </AppText>Your information collected through the
          Service may be stored and processed in the United States or any other country in which
          Players Health or its subsidiaries, affiliates or service providers maintain facilities.
          If you are located in the European Union or other regions with laws governing data
          collection and use that may differ from U.S. law, please note that we may transfer
          information, including personal information, to a country and jurisdiction that does not
          have the same data protection laws as your jurisdiction, and you consent to the transfer
          of information to the U.S. or any other country in which Players Health or its affiliates
          or service providers maintain facilities as well as to the use and disclosure of
          information about you in connection with the foregoing as described in this Privacy
          Policy.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Changes to this Privacy Policy: </AppText>Players Health may change this
          Privacy Policy from time to time. If we make material changes to the Privacy Policy, we
          will provide notice to you though the Service or by some other means, such as email.
          Please review this Privacy Policy frequently, and any notice we provide about material
          changes carefully. If you agree to the changes, simply continue to use our Service. If you
          object to any of the changes to our terms and you no longer wish to use our Service,
          please let us know. Users may not be able to close their accounts without first finding an
          alternative arrangement to handle their Health records with their sports organization.
          This Privacy Policy applies to all information that we have about you and your account.
          Using our Service after a notice of changes has been communicated to you or published on
          the Service shall constitute consent to the changed terms or practices.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• California's Shine the Light Law: </AppText>California Civil Code Section
          1798.83, known as the “Shine The Light” law, permits Users who are California residents to
          request and obtain from us a list of what personal information (if any) we disclosed to
          third parties for direct marketing purposes in the preceding calendar year and the names
          and addresses of those third parties. Requests may be made only once a year and are free
          of charge. Under Section 1798.83, we currently do not share any personal information with
          third parties for their own direct marketing purposes.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• California's Do-Not-Track Disclosure: </AppText>As required by the
          California Online Privacy Protection Act (California Business and Professions Code
          Sections 22575-22579), Players Health notes that although we do our best to honor the
          privacy preferences of our Users, and have implemented industry standard safeguards as
          described above, we do not respond to Do-Not-Track signals from your browser at this time
          due to the lack of a neutral and consistent industry standard. Players Health does not
          collect personal information about your online activities over time and across third party
          websites or services. However, when Players Health uses third-party services, data, and
          software tools to target advertising to you, these third parties may collect personal
          information about your online activities over time and across third party websites or
          services.
        </AppText>
        <Spacing />

        <AppText style={[styles.tab, styles.text]}>
          <AppText bold>• Users under the age of 13: </AppText>The Service is a general audience
          site not directed at children under the age of 13. Unless listed as a dependent under an
          adult’s insurance policy or other insurance benefits, Players Health does not knowingly
          collect or solicit any information from anyone under the age of 13 or knowingly allow such
          persons to register as Users. In the event that we learn that we have collected personal
          information from a child under the age of 13, we will delete that information as quickly
          as possible. If you believe that we might have any information from a child under 13 that
          is not a dependent of one of our Users, please contact us as set out in Section 5 below.
        </AppText>

        <Spacing />
        <Spacing />

        <AppText bold style={styles.text}>
          CONTACT US
        </AppText>
        <Spacing />

        <AppText style={styles.text}>You may contact us by mail at:</AppText>
        <AppText style={styles.text}>Privacy Policy Issues</AppText>
        <AppText style={styles.text}>Players Health Privacy Officer</AppText>
        <AppText style={styles.text}>718 Washington Ave Suite 401</AppText>
        <AppText style={styles.text}>Minneapolis, MN 55401</AppText>
        <AppText style={styles.text}>
          You may also reach us by at <AppText bold>support@playershealth.com</AppText> or by
          submitting a support ticket.
        </AppText>
      </ScrollView>
      <Button
        style={styles.button}
        onPress={onAccepted}
        type="primary"
        text="Accept"
        disabled={!policyRead}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: { maxHeight: '75vh', marginBottom: 10 },
  containerSmall: {
    maxHeight: '60vh',
    maxWidth: '70vw',
    marginBottom: 10,
  },
  title: { fontSize: 24, marginBottom: 8 },
  text: { lineHeight: 24 },
  tab: { marginLeft: 20 },
  largeTab: { marginLeft: 40 },
  button: { marginTop: 36 },
})
