import React, { useState } from 'react'
import { Pressable } from 'react-native'
import Popover, { PopoverMode, PopoverPlacement } from 'react-native-popover-view'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { COLORS } from '@assets/constants'

export const ListPopover: React.FC = ({ children }) => {
  const [showPopover, setShowPopover] = useState(false)

  return (
    <Popover
      mode={PopoverMode.JS_MODAL}
      placement={PopoverPlacement.BOTTOM}
      isVisible={showPopover}
      onRequestClose={() => setShowPopover(false)}
      arrowSize={{ height: 0, width: 0 }}
      from={
        <Pressable onPress={() => setShowPopover(!showPopover)}>
          <MaterialCommunityIcons name="dots-vertical" size={20} color={COLORS.GRAY_700} />
        </Pressable>
      }>
      {children}
    </Popover>
  )
}
