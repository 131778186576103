import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  cloneElement,
  useCallback,
  useEffect,
} from 'react'
import { ModalHandles, ModalOptions } from './types'
import {
  View,
  StyleSheet,
  Modal as RnModal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Image,
  ScrollView,
} from 'react-native'
import { AppText, Button, Row } from '@views/components'

import successImg from '@assets/images/sucess-check.png'
import warningImg from '@assets/images/warning.png'
import close from '@assets/images/close.png'
import { useSystemConfig } from '@views/hooks'

const MODAL_DEFAULT_OPTIONS: ModalOptions = {
  dismiss: true,
}

const Modal: ForwardRefRenderFunction<ModalHandles> = (_, ref) => {
  const [visible, setVisible] = useState(false)
  const [options, setOptions] = useState<ModalOptions>({} as ModalOptions)
  const { isWeb } = useSystemConfig()

  const openModal = useCallback((modalOptions: ModalOptions) => {
    setOptions({ ...MODAL_DEFAULT_OPTIONS, ...modalOptions })
    setVisible(true)
  }, [])
  const closeModal = useCallback(() => setVisible(false), [])

  const handleBackOnBrowser = () => {
    const onPopBack = () => {
      if (visible) closeModal()
    }
    window.addEventListener('popstate', onPopBack)
    return () => window.removeEventListener('popstate', onPopBack)
  }

  useEffect(isWeb ? handleBackOnBrowser : () => {}, [visible])

  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  )

  const getImage = () => {
    if (!options.type && !options.modalImagePath) return

    let image
    if (options.type === 'success') image = successImg
    if (options.type === 'warning') image = warningImg
    if (options.modalImagePath) image = options.modalImagePath

    return <Image source={image} style={styles.image} />
  }

  const getContent = () => {
    return (
      <>
        {options.dismiss ? (
          <Row style={styles.headerRow}>
            <AppText bold style={styles.title}>
              {options.title}
            </AppText>
            <TouchableOpacity onPress={closeModal}>
              <Image source={close} style={styles.closeBtn} />
            </TouchableOpacity>
          </Row>
        ) : (
          <AppText bold style={styles.title}>
            {options.title}
          </AppText>
        )}
        {options.content ? (
          options.content
        ) : (
          <ScrollView contentContainerStyle={styles.scrollContent}>
            <AppText style={styles.subtitle}>{options.subtitle}</AppText>
          </ScrollView>
        )}
      </>
    )
  }

  const getButtons = () => {
    if (!options.buttons) return getDefaultButtons()

    return options.buttons.map((button) => {
      return cloneElement(button, {
        onPress: () => {
          closeModal()
          button.props.onPress?.()
        },
      })
    })
  }

  const getDefaultButtons = () => {
    let buttons = []
    if (options?.onConfirmPressed || options?.confirmationButtonMessage)
      buttons.push(
        <Button
          key="confirmation"
          onPress={() => {
            closeModal()
            options?.onConfirmPressed?.()
          }}
          text={options.confirmationButtonMessage || 'Confirm'}
        />
      )
    if (options?.onCancelPressed || options?.cancelButtonMessage)
      buttons.push(
        <Button
          key="cancel"
          onPress={() => {
            closeModal()
            options?.onCancelPressed?.()
          }}
          type="secondary"
          text={options.cancelButtonMessage || 'Cancel'}
        />
      )
    return buttons
  }

  return (
    <RnModal visible={visible} animationType="fade" transparent onRequestClose={closeModal}>
      <TouchableWithoutFeedback onPress={() => options.dismiss && closeModal()}>
        <View style={[styles.container, options.containerStyle]}>
          <TouchableWithoutFeedback>
            <View style={[styles.content, options.contentStyle]}>
              {getImage()}
              {getContent()}
              {getButtons()}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </RnModal>
  )
}

export default forwardRef(Modal)

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  scrollContent: {
    flex: 1,
  },
  headerRow: {
    justifyContent: 'space-between',
  },
  closeBtn: {
    width: 20,
    height: 20,
  },
  content: {
    flexGrow: 0,
    backgroundColor: 'white',
    borderRadius: 12,
    maxWidth: 480,
    margin: 16,
    padding: 36,
  },
  image: {
    width: 100,
    height: 60,
    resizeMode: 'contain',
    marginBottom: 24,
    alignSelf: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 24,
    lineHeight: 32,
    marginBottom: 8,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 32,
  },
  cancelButton: {
    marginTop: 32,
  },
})
