import { AppText, Button } from '@views/components'
import { useAuth } from '@views/hooks'
import React from 'react'
import { View, StyleSheet } from 'react-native'

const SettingsPage: React.FC = () => {
  const { logout } = useAuth()

  return (
    <View style={styles.container}>
      <AppText semiBold style={{ fontSize: 30, marginBottom: 12 }}>
        SettingsPage
      </AppText>

      <Button style={styles.button} onPress={() => logout()} text="Logout" />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    maxWidth: 400,
    width: '80%',
  },
})

export default SettingsPage
