import {
  BadRequestError,
  NotFoundError,
  ServerError,
  UnauthenticatedError,
  UnauthorizedError,
} from '.'

export const ErrorFromStatus = (status?: number) => {
  const errors: Record<number, Error> = {
    400: new BadRequestError(),
    401: new UnauthenticatedError(),
    403: new UnauthorizedError(),
    404: new NotFoundError(),
    500: new ServerError(),
  }
  if (!status) return null
  if (errors[status]) return errors[status]
}
