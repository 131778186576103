import { InputTypes } from '@views/components/Input'
import { IInputProps } from '@views/components/Form'
import { orgTypes, states } from '@assets/constants/pickerItems'
import * as yup from 'yup'
import { noSelectionRegex, phoneRegex, einRegex, zipRegex } from '@assets/constants'

export const basicInfoFields: IBasicInfoFields = {
  orgLegalName: {
    name: 'orgLegalName',
    label: 'Organization legal name',
    type: InputTypes.text,
    placeholder: 'Enter organization legal name',
  },
  orgType: {
    name: 'orgType',
    label: 'Organization type',
    type: InputTypes.picker,
    placeholder: 'Enter organization type',
    items: orgTypes,
  },
  orgEIN: {
    name: 'orgEIN',
    label: 'EIN (Employer Identification Number)',
    type: InputTypes.text,
    placeholder: 'Enter EIN',
  },
  orgFormationDate: {
    name: 'orgFormationDate',
    label: 'Organization formation date',
    type: InputTypes.text,
    placeholder: 'MM/DD/YYYY',
  },
  orgFormationState: {
    name: 'orgFormationState',
    label: 'Organization formation state',
    type: InputTypes.picker,
    placeholder: 'Select organization formation state',
    items: states,
  },
  orgPhone: {
    name: 'orgPhone',
    label: 'Organization phone number',
    type: InputTypes.text,
    placeholder: 'Enter organization phone number',
  },
  orgEmail: {
    name: 'orgEmail',
    label: 'Organization email address',
    type: InputTypes.text,
    placeholder: 'Enter organization email address',
  },
  orgLegalStructure: {
    name: 'orgLegalStructure',
    label: 'Organization legal structure',
    type: InputTypes.picker,
    placeholder: 'Enter organization legal structure',
    items: [],
  },
  orgAddrStreet1: {
    name: 'orgAddrStreet1',
    label: 'Street address',
    type: InputTypes.text,
    placeholder: 'Enter street address',
  },
  orgAddrStreet2: {
    name: 'orgAddrStreet2',
    label: 'Apartment, suite, etc (optional)',
    type: InputTypes.text,
    placeholder: 'Optional',
  },
  orgAddrCity: {
    name: 'orgAddrCity',
    label: 'City',
    type: InputTypes.text,
    placeholder: 'Enter address city',
  },
  orgAddrState: {
    name: 'orgAddrState',
    label: 'Address state',
    type: InputTypes.picker,
    placeholder: 'Select address state',
    items: states,
  },
  orgAddrZip: {
    name: 'orgAddrZip',
    label: 'ZIP code',
    type: InputTypes.text,
    placeholder: 'Enter address ZIP',
  },
}

export interface IBasicInfoFields {
  orgLegalName: IInputProps
  orgType: IInputProps
  orgEIN: IInputProps
  orgFormationDate: IInputProps
  orgFormationState: IInputProps
  orgPhone: IInputProps
  orgEmail: IInputProps
  orgLegalStructure: IInputProps
  orgAddrStreet1: IInputProps
  orgAddrStreet2: IInputProps
  orgAddrCity: IInputProps
  orgAddrState: IInputProps
  orgAddrZip: IInputProps
}

export interface IBasicInfo {
  orgId?: string
  orgLegalName: string
  orgType: string
  orgEIN: string
  orgFormationDate: string
  orgFormationState: string
  orgPhone: string
  orgEmail: string
  orgLegalStructure: string
  orgAddrStreet1: string
  orgAddrStreet2: string
  orgAddrCity: string
  orgAddrState: string
  orgAddrZip: string
}

export const BasicInfoSchema = yup.object().shape({
  orgLegalName: yup.string().required('Required'),
  orgType: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  orgEIN: yup.string().trim().matches(einRegex, 'Invalid EIN format').required('Required'),
  orgFormationDate: yup.date().typeError('Invalid date').required('Required'),
  orgFormationState: yup
    .string()
    .matches(noSelectionRegex, 'Make a selection')
    .required('Required'),
  orgPhone: yup.string().trim().matches(phoneRegex, 'Invalid phone format').required('Required'),
  orgEmail: yup.string().email('Invalid email').required('Required'),
  orgLegalStructure: yup
    .string()
    .matches(noSelectionRegex, 'Make a selection')
    .required('Required'),
  orgAddrStreet1: yup.string().required('Required'),
  orgAddrStreet2: yup.string(),
  orgAddrCity: yup.string().required('Required'),
  orgAddrState: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  orgAddrZip: yup
    .string()
    .required('Required')
    .matches(zipRegex, 'Invalid Zipcode format')
    .min(5, 'Invalid Zipcode format')
    .max(5, 'Invalid Zipcode format'),
})
