import React from 'react'
import { View, ViewStyle, StyleSheet } from 'react-native'

interface Props {
  style?: ViewStyle
}

export const Spacing: React.FC<Props> = ({ style }) => {
  return <View style={[styles.container, style]} />
}

const styles = StyleSheet.create({
  container: { height: 10, width: 10 },
})
