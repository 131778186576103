import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Button, Form, FormInput } from '@views/components'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppText } from '../AppText'
import { COLORS } from '@assets/constants'
import { useSystemConfig } from '@views/hooks'

type Props = {
  field: any
  schema: any
  subLabel?: string
  onSubmit: (data: any) => any
}

export const InputModal: React.FC<Props> = ({ field, schema, subLabel, onSubmit }) => {
  const { isSmallDevice } = useSystemConfig()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <View style={!isSmallDevice && styles.container}>
      <Form showButtons={false}>
        <FormInput
          name={field.name}
          label={field.label}
          type={field.type}
          control={control}
          error={errors[field.name] as unknown as FieldError}
          placeholder={field.placeholder}
          items={field.items}
        />
        {subLabel ? (
          <AppText light style={styles.subLabel}>
            {subLabel}
          </AppText>
        ) : null}
        <Button
          style={styles.button}
          text="Submit"
          onPress={handleSubmit(onSubmit)}
          type="primary"
        />
      </Form>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 400,
  },
  subLabel: {
    fontSize: 12,
    color: COLORS.GRAY_700,
  },
  button: {
    marginTop: 32,
  },
})
