import React from 'react'
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'
import { AppText } from '@views/components'
import { COLORS } from '@assets/constants'
import { currencyFormatter } from '@views/utils'
import { teamsInfo } from '../../../../../mock/teamInfoMock'

type Props = {
  accIndex: any
  setAccIndex: any
}

export type teamsGeneralInfo = {
  teamName: string
  teamOwner: string
  teamBalance: number
}

const TeamTabs: React.FC<Props> = ({ accIndex, setAccIndex }) => {
  return (
    <View>
      {teamsInfo.length > 0 ? (
        <ScrollView
          horizontal={true}
          contentInsetAdjustmentBehavior="automatic"
          contentContainerStyle={styles.teamTabs}>
          {teamsInfo.map((team, teamIndex) => {
            return (
              <TouchableOpacity
                key={teamIndex}
                style={teamIndex === accIndex ? styles.teamTabSelected : styles.teamTab}
                onPress={() => setAccIndex(teamIndex)}>
                <View>
                  <AppText style={styles.teamName}>{team.teamName}</AppText>
                  <AppText style={styles.teamOwner}>{team.teamOwner}</AppText>
                </View>
                <AppText style={styles.teamBalance}>{currencyFormatter(team.teamBalance)}</AppText>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      ) : (
        <AppText style={styles.addSubAcc}>Add a sub-account to get started</AppText>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  teamTabs: {
    alignItems: 'center',
  },
  teamTab: {
    borderRadius: 4,
    padding: 16,
    paddingVertical: 25,
    width: 270,
    marginRight: 8,
    marginBottom: 20,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: COLORS.WHITE,
  },
  teamTabSelected: {
    borderRadius: 4,
    padding: 16,
    paddingVertical: 25,
    width: 270,
    marginRight: 8,
    marginBottom: 20,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: COLORS.WHITE,
    borderWidth: 2,
    borderColor: COLORS.PRIMARY,
  },
  teamName: {
    fontSize: 14,
    fontWeight: '600',
    marginBottom: 5,
  },
  teamOwner: {
    fontSize: 12,
    fontWeight: '300',
  },
  teamBalance: {
    fontSize: 16,
    fontWeight: '800',
  },
  addSubAcc: {
    marginBottom: 30,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '400',
  },
})

export default TeamTabs
