import React from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ListItem, Avatar } from '@rneui/themed'

import { AppText } from '@views/components'
import { COLORS, orgInfo } from '@assets/constants'
import CheckmarkFilled from '@assets/images/checkmark_filled.png'

interface Props {
  currentPageIdx: number
}

export const CreateAccountContent: React.FC<Props> = ({ currentPageIdx }) => {
  const navigation = useNavigation()
  const isFunding = currentPageIdx > orgInfo.length
  return (
    <View style={styles.container}>
      <ListItem.Accordion
        noIcon
        isExpanded={!isFunding}
        content={
          <>
            <TouchableOpacity
              disabled={!isFunding}
              onPress={() => {
                navigation.navigate('createAccount', {
                  screen: 'sportInfo',
                })
              }}>
              <Avatar
                rounded={false}
                title={'1'}
                titleStyle={!isFunding ? styles.avatarTitleActive : styles.avatarTitle}
                containerStyle={!isFunding ? styles.avatarActive : styles.avatar}
              />
            </TouchableOpacity>
            <AppText style={!isFunding ? styles.accordionTitleActive : styles.accordionTitle}>
              Organization info
            </AppText>
          </>
        }>
        {orgInfo.map((step, idx) => {
          const isDisabled = idx >= currentPageIdx
          const isActive = idx === currentPageIdx
          return (
            <TouchableOpacity
              key={`orgInfo_step_${idx}`}
              style={styles.sublistItem}
              /* @TODO after demo finish functionality disabled={isDisabled}*/
              onPress={() =>
                navigation.navigate('createAccount', {
                  screen: step.route,
                })
              }>
              {isDisabled ? (
                <View style={isActive ? styles.checkmarkActive : styles.checkmark} />
              ) : (
                <Image source={CheckmarkFilled} style={styles.checkmarkImg} />
              )}
              <AppText style={isActive ? styles.subtitleActive : styles.subtitle}>
                {step.title}
              </AppText>
            </TouchableOpacity>
          )
        })}
      </ListItem.Accordion>
      <ListItem.Accordion
        isExpanded={isFunding}
        noIcon
        content={
          <>
            <Avatar
              rounded={false}
              title={'2'}
              titleStyle={isFunding ? styles.avatarTitleActive : styles.avatarTitle}
              containerStyle={isFunding ? styles.avatarActive : styles.avatar}
            />
            <AppText style={isFunding ? styles.accordionTitleActive : styles.accordionTitle}>
              Fund your account
            </AppText>
          </>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh',
  },
  accordionTitle: {
    color: COLORS.GRAY_700,
    width: 215,
  },
  accordionTitleActive: {
    color: COLORS.PRIMARY,
    width: 215,
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 4,
    marginRight: 12,
    backgroundColor: COLORS.BACKGROUND,
  },
  avatarActive: {
    width: 32,
    height: 32,
    borderRadius: 4,
    marginRight: 12,
    backgroundColor: COLORS.PRIMARY,
  },
  avatarTitle: {
    color: COLORS.DARK,
  },
  avatarTitleActive: {
    color: COLORS.WHITE,
  },
  subtitle: {
    color: COLORS.GRAY_700,
    fontSize: 12,
  },
  subtitleActive: {
    color: COLORS.PRIMARY,
    fontSize: 12,
  },
  sublistItem: {
    minHeight: 18,
    marginBottom: 18,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  checkmark: {
    width: 12,
    height: 12,
    borderRadius: 2,
    borderWidth: 1.2,
    borderStyle: 'solid',
    marginRight: 10,
    borderColor: COLORS.GRAY_700,
  },
  checkmarkActive: {
    width: 12,
    height: 12,
    borderRadius: 2,
    borderWidth: 1.2,
    borderStyle: 'solid',
    marginRight: 10,
    borderColor: COLORS.PRIMARY,
    boxSizing: 'border-box',
  },
  checkmarkImg: {
    width: 12,
    height: 16,
    marginRight: 10,
    boxSizing: 'border-box',
  },
})
