import React from 'react'
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'
import { AppText } from '@views/components'
import { COLORS } from '@assets/constants'
import { currencyFormatter } from '@views/utils'
import { teamsInfo } from '../../../../../mock/teamInfoMock'

type Props = {
  accIndex: any
  setAccIndex: any
}

const TeamTabs: React.FC<Props> = ({ accIndex, setAccIndex }) => {
  return (
    <View>
      {teamsInfo.length > 0 ? (
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          contentContainerStyle={styles.teamTabs}>
          {teamsInfo.map((team, teamIndex) => {
            return (
              <TouchableOpacity
                key={teamIndex}
                style={
                  teamIndex === accIndex
                    ? styles.teamTabSelected
                    : [
                        styles.teamTab,
                        { paddingBottom: teamIndex === teamsInfo.length - 1 ? 18 : 2 },
                      ]
                }
                onPress={() => setAccIndex(teamIndex)}>
                <View>
                  <AppText style={styles.teamName}>{team.teamName}</AppText>
                  <AppText style={styles.teamOwner}>{team.teamOwner}</AppText>
                </View>
                <AppText style={styles.teamBalance}>{currencyFormatter(team.teamBalance)}</AppText>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      ) : (
        <AppText style={styles.addSubAcc}>Add a sub-account to get started</AppText>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  teamTabs: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: COLORS.WHITE,
    borderRadius: 4,
    margin: 5,

    shadowColor: COLORS.DARK,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    elevation: 12,
  },
  teamTab: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 18,
    borderTopWidth: 1,
    borderTopColor: COLORS.BACKGROUND,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  teamTabSelected: {
    borderRadius: 4,
    padding: 18,
    borderWidth: 2,
    borderColor: COLORS.PRIMARY,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  teamName: {
    fontSize: 14,
    fontWeight: '500',
    marginBottom: 5,
  },
  teamOwner: {
    fontSize: 12,
    fontWeight: '300',
  },
  teamBalance: {
    fontSize: 16,
    fontWeight: '700',
  },
  addSubAcc: {
    marginBottom: 30,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '400',
  },
})

export default TeamTabs
