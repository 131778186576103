import React from 'react'
import { createDrawerNavigator, DrawerContentComponentProps } from '@react-navigation/drawer'

import { CreateAccountParamList } from '@views/navigation/types'
import { useSystemConfig } from '@views/hooks'
import { LeftPanel, CreateAccountContent } from '@views/components'

import BasicInfo from '@views/pages/CreateAccount/BasicInfo'
import AdminInfo from '@views/pages/CreateAccount/AdminInfo'
import BeneficialOwners from '@views/pages/CreateAccount/BeneficialOwners'
import SportInfo from '@views/pages/CreateAccount/SportInfo'
import BusinessDocuments from '@views/pages/CreateAccount/BusinessDocuments'
import BeneficialOwnersReview from '@views/pages/CreateAccount/BeneficialOwnersReview'
import FundAccount from '@views/pages/CreateAccount/FundAccount'

import { documentTitle } from '@views/utils'
import { CreateAccountProvider } from '@views/contexts'

const Drawer = createDrawerNavigator<CreateAccountParamList>()

const DrawerContent = ({ navigation }: DrawerContentComponentProps) => {
  const state = navigation.getState()
  return <LeftPanel content={<CreateAccountContent currentPageIdx={state.index} />} />
}

export default function CreateAccount() {
  // @TODO add tab navigation
  // Hid drawer on mobile for now
  const { isSmallDevice } = useSystemConfig()
  return (
    <CreateAccountProvider>
      <Drawer.Navigator
        initialRouteName="basicInfo"
        useLegacyImplementation
        screenOptions={{
          headerShown: false,
          drawerType: isSmallDevice ? 'slide' : 'permanent',
        }}
        drawerContent={DrawerContent}>
        <Drawer.Screen
          name="basicInfo"
          component={BasicInfo}
          options={{ title: documentTitle('Create Account', 'Basic Info') }}
        />
        <Drawer.Screen
          name="adminInfo"
          component={AdminInfo}
          options={{ title: documentTitle('Create Account', 'Admin Info') }}
        />
        <Drawer.Screen
          name="beneficialOwners"
          component={BeneficialOwners}
          options={{ title: documentTitle('Create Account', 'Beneficial Owners') }}
        />
        <Drawer.Screen
          name="sportInfo"
          component={SportInfo}
          options={{ title: documentTitle('Create Account', 'Sport Info') }}
        />
        <Drawer.Screen
          name="beneficialOwnersReview"
          component={BeneficialOwnersReview}
          options={{ title: documentTitle('Create Account', `Owners' Info Review`) }}
        />
        <Drawer.Screen
          name="businessDocuments"
          component={BusinessDocuments}
          options={{ title: documentTitle('Create Account', 'Business Documents') }}
        />
        <Drawer.Screen
          name="fundAccount"
          component={FundAccount}
          options={{ title: documentTitle('Create Account', 'Fund Account') }}
        />
      </Drawer.Navigator>
    </CreateAccountProvider>
  )
}
