import React, { useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import { AppText } from '@views/components'
import { COLORS } from '@assets/constants'
import TeamTabs from './TeamTabs'
import addIcon from '@assets/images/add-icon.png'
import transferIcon from '@assets/images/transfer-icon.png'

type Props = {
  setIssuedMemberCards: any
  setDepositsMade: any
}

const BusinessAccountDrawer: React.FC<Props> = ({ setIssuedMemberCards, setDepositsMade }) => {
  const [accIndex, setAccIndex] = useState(-1)

  return (
    <View style={styles.businessAccDrawerContainer}>
      <AppText style={styles.drawerTitle}>Business Account</AppText>
      <AppText style={styles.accHeader}>Account</AppText>
      <TouchableOpacity onPress={() => setAccIndex(-1)}>
        <AppText
          style={[styles.accTitle, { color: accIndex === -1 ? COLORS.PRIMARY : COLORS.DARK }]}>
          Ohio Rockets Club
        </AppText>
      </TouchableOpacity>
      <AppText style={styles.subAcc}>Sub-accounts</AppText>

      <TeamTabs accIndex={accIndex} setAccIndex={setAccIndex} />

      <TouchableOpacity
        style={styles.addButton}
        onPress={() =>
          // @TODO: THIS IS HERE JUST FOR TESTING
          setIssuedMemberCards(true)
        }>
        <Image style={styles.actionIcon} source={addIcon} />
        <AppText style={{ color: COLORS.WHITE }}>Add New Sub-Account</AppText>
      </TouchableOpacity>

      <TouchableOpacity
        style={styles.transferButton}
        onPress={() =>
          // @TODO: THIS IS HERE JUST FOR TESTING
          setDepositsMade(true)
        }>
        <Image style={styles.actionIcon} source={transferIcon} />
        <AppText style={{ color: COLORS.DARK }}>Transfer Funds</AppText>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  businessAccDrawerContainer: {
    height: '100%',
    width: '27%',
    minWidth: 270,
    padding: 30,
    backgroundColor: COLORS.GRAY_200,
  },
  drawerTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 30,
  },
  accHeader: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 10,
  },
  accTitle: {
    fontSize: 23,
    fontWeight: '700',
    marginBottom: 16,
    paddingBottom: 16,
    borderBottomWidth: 3,
    borderBottomColor: COLORS.BACKGROUND,
  },
  subAcc: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 16,
  },
  addButton: {
    marginTop: 30,
    marginBottom: 10,
    backgroundColor: COLORS.DARK,
    height: 60,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    borderRadius: 4,
    borderColor: COLORS.DARK,
    borderWidth: 2,
  },
  transferButton: {
    backgroundColor: 'transparent',
    height: 60,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    borderRadius: 4,
    borderColor: COLORS.DARK,
    borderWidth: 2,
  },
  actionIcon: {
    marginRight: 10,
    resizeMode: 'contain',
    height: 13,
    width: 13,
  },
})

export default BusinessAccountDrawer
