import React from 'react'
import { AppText, Button, FormInput, InputTypes } from '@views/components'
import { View, ScrollView, StyleSheet } from 'react-native'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { moneyRegex } from '@assets/constants'
import * as yup from 'yup'

const internalTransferSchema = yup.object().shape({
  amount: yup.string().required('Required').matches(moneyRegex, 'Invalid format'),
  from: yup.string().required('Required'),
  to: yup.string().required('Required'),
})

export const InternalTransfer = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(internalTransferSchema),
  })

  const onSubmit = (data: any) => console.log('INTERNAL TRANSFER', data)

  return (
    <>
      <View style={styles.header}>
        <AppText bold style={styles.title}>
          Internal Transfer
        </AppText>
      </View>
      <ScrollView>
        <FormInput
          type={InputTypes.text}
          name={'from'}
          label={'From'}
          control={control}
          error={errors.from as unknown as FieldError}
          placeholder={'Ohio Rockets Club'}
        />
        <FormInput
          type={InputTypes.picker}
          items={[
            { label: 'item1', value: 'a' },
            { label: 'item2', value: 'b' },
            { label: 'item3', value: 'c' },
          ]}
          name={'to'}
          label={'To'}
          control={control}
          error={errors.to as unknown as FieldError}
          placeholder={'Enter Sub-account name'}
        />
        <FormInput
          type={InputTypes.text}
          name={'amount'}
          label={'Amount'}
          control={control}
          error={errors.amount as unknown as FieldError}
          placeholder={'Enter amount'}
        />
        <Button onPress={handleSubmit(onSubmit)} text="Transfer" />
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  title: { fontSize: 24, marginBottom: 8 },
  closeIcon: { width: 20, height: 20, resizeMode: 'contain' },
  header: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
})
