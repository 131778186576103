import React from 'react'
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'

import { AppText } from '@views/components'
import { COLORS } from '@assets/constants'

type Props = {
  onPress: () => any
  text: string
  type?: 'primary' | 'secondary' | 'light'
  disabled?: boolean
  style?: ViewStyle
}

const DISABLED_OPACITY = 0.4
const ACTIVE_OPACITY = 0.2

export const Button: React.FC<Props> = ({
  onPress,
  type = 'primary',
  text,
  disabled = false,
  style,
}) => {
  const isPrimary = type === 'primary'

  return (
    <TouchableOpacity
      activeOpacity={disabled ? DISABLED_OPACITY : ACTIVE_OPACITY}
      style={[styles.button, styles[type], style, disabled && styles.disabled]}
      onPress={disabled ? undefined : onPress}>
      <AppText style={{ color: isPrimary ? COLORS.WHITE : COLORS.DARK }}>{text}</AppText>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    height: 60,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    borderRadius: 4,
  },
  primary: {
    backgroundColor: COLORS.DARK,
  },
  secondary: {
    backgroundColor: 'transparent',
    borderColor: COLORS.DARK,
    borderWidth: 1,
  },
  light: {
    backgroundColor: COLORS.WHITE,
  },
  disabled: {
    opacity: DISABLED_OPACITY,
  },
})
