import * as yup from 'yup'
import { notNumRegex, noSelectionRegex, sports, months } from '@assets/constants'
import { IInputProps } from '@views/components/Form'
import { InputTypes } from '@views/components/Input'

export const sportInfoFields: ISportInfoFields = {
  membersNum: {
    name: 'membersNum',
    label: 'Number of members',
    type: InputTypes.text,
    placeholder: 'Enter a number',
  },
  sportType: {
    name: 'sportType',
    label: 'Sport type',
    type: InputTypes.picker,
    placeholder: 'Select a sport',
    items: sports,
  },
  seasonStartMonth: {
    name: 'seasonStartMonth',
    label: 'Season start month',
    type: InputTypes.picker,
    placeholder: 'Select a month',
    items: months,
  },
  seasonMonthCount: {
    name: 'seasonMonthCount',
    label: 'Season month count',
    type: InputTypes.slider,
  },
}

export interface ISportInfoFields {
  membersNum: IInputProps
  sportType: IInputProps
  seasonStartMonth: IInputProps
  seasonMonthCount: IInputProps
}

export interface ISportInfo {
  membersNum: string
  sportType: string
  seasonStartMonth: string
  seasonMonthCount: string
}

export const SportInfoSchema = yup.object().shape({
  membersNum: yup
    .string()
    .required('Required')
    .matches(notNumRegex, 'Invalid Number')
    .min(1, 'Invalid Number')
    .max(5, 'Invalid Number'),
  sportType: yup.string().matches(noSelectionRegex, 'Select a sport').required('Required'),
  seasonStartMonth: yup.string().matches(noSelectionRegex, 'Select a month').required('Required'),
})
